import * as React from "react";
import {createElement} from "react";
import {Box, Grid, TableCell, TableRow, Typography} from "@material-ui/core";
import {gs} from "../../theme";
import {BlockSvg} from "../BlockSvg";

interface EmptyTableProps {
    title: string;
    info: string;
    src: string;
    colSpan: number;
}

export function EmptyTable({title, info, src, colSpan}: EmptyTableProps) {
    return (
        <TableRow>
            <TableCell colSpan={colSpan}>
                <Box p={6}>
                    <Grid container spacing={gs}>
                        <Grid item xs={12}>
                            <Typography variant="h3" align="center">{title}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <BlockSvg src={src} large/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" align="center">{info}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </TableCell>
        </TableRow>
    );
}
