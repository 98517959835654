import {Inventory} from "./types";
import {Api} from "../Api";

export class InventoryProvider {

    static get(tenantId: string, inventoryId: string): Promise<Inventory> {
        let path = `tenants/${tenantId}/inventory/${inventoryId}`;
        return Api.GET<Inventory>(path);
    }

    static checkLowInventory(tenantId: string): Promise<void> {
        const path = `tenants/${tenantId}/inventory/check-low-inventory`;
        return Api.GET(path);
    }

}
