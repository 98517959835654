import {Page, Pageable, Category, CategoryCreateRequest} from "./types";
import {Api} from "../Api";
import {paging} from "./paging";

export class CategoriesProvider {

    static get(tenantId: string, categoryId: string): Promise<Category> {
        return Api.GET<Category>(`tenants/${tenantId}/categories/${categoryId}`);
    }

    static list(tenantId: string, pageable: Pageable): Promise<Page<Category>> {
        const path = paging.attachToUrl(pageable, `tenants/${tenantId}/categories`);
        return Api.GET<Page<Category>>(path);
    }

    static create(tenantId: string, request: CategoryCreateRequest) {
        return Api.POST<Category>(`tenants/${tenantId}/categories`, request);
    }

    static save(tenantId: string, request: Category) {
        return Api.PUT<Category>(`tenants/${tenantId}/categories`, request);
    }

    static delete(tenantId: string, id: string) {
        return Api.DELETE<Category>(`tenants/${tenantId}/categories/${id}`);
    }
}
