import * as React from "react";
import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Typography} from "@material-ui/core";
import {createElement, useState} from "react";
import {Page, Category} from "../../domain/types";
import {messages} from "../../i18n";
import {EmptyTable} from "../base/EmptyTable";
import notFound from "../../assets/undraw_not_found_60pq.svg";

type Column = 'id' | 'name' | 'verison';

export const availableColumns = ['id', 'name', 'version'];
export const defaultColumns = ['id', 'name', 'version'];

type sortKey = 'id' | 'name' | 'version';

interface CategoriesTableProps {
    categories: Page<Category>;
    columns?: Column[];
    onOpen: (category: Category) => void;
    selectedCategories?: Category[];
    onSelectionChanged?: (selectedCategories: Category[]) => void;
    onSortChange: (sort: string, direction: 'asc' | 'desc') => void;
}

export function CategoriesTable(props: CategoriesTableProps) {
    const {categories, onOpen, selectedCategories, onSelectionChanged} = props;
    const columns = props.columns || defaultColumns;
    const allowSelection = onSelectionChanged != undefined && selectedCategories != undefined;

    const showColumn = (column: Column) => {
        return columns.indexOf(column) != -1;
    }

    const categorySelected = (category: Category) => {
        if (selectedCategories) {
            return selectedCategories
                .map(c => c.id)
                .indexOf(category.id) != -1;
        }
        return false;
    }

    const selectCategory = (category: Category, checked: boolean) => {
        if (selectedCategories && onSelectionChanged) {
            if (checked) {
                onSelectionChanged([ ...selectedCategories, category]);
            } else {
                onSelectionChanged(selectedCategories.filter(c => c.id != category.id));
            }
        }
    }

    const [orderBy, setOrderBy] = useState<sortKey>('name');
    const [orderAsc, setOrderAsc] = useState<boolean>(false);
    const onSort = (key: sortKey) => {
        const {onSortChange} = props;
        if (orderBy === key) {
            const isAsc = !orderAsc;
            setOrderAsc(isAsc);
            if (onSortChange) {
                onSortChange(key, (isAsc) ? 'asc' : 'desc');
            }
        } else {
            setOrderBy(key);
            if (onSortChange) {
                onSortChange(key, orderAsc ? 'asc' : 'desc');
            }
        }
    }

    const colspan = 3 + columns.length

    return (
        <Table size="small" stickyHeader>
            <TableHead>
                <TableRow>
                    {allowSelection && (
                        <TableCell padding="checkbox">
                            TABLE SELECT ALL
                        </TableCell>
                    )}
                    <TableCell>
                        <TableSortLabel active={orderBy === 'id'}
                                        direction={orderAsc ? 'asc' : 'desc'}
                                        onClick={() => onSort('id')}>
                            {messages.categories.id}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={orderBy === 'name'}
                                        direction={orderAsc ? 'asc' : 'desc'}
                                        onClick={() => onSort('name')}>
                            {messages.categories.name}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={orderBy === 'version'}
                                        direction={orderAsc ? 'asc' : 'desc'}
                                        onClick={() => onSort('version')}>
                            {messages.categories.version}
                        </TableSortLabel>
                    </TableCell>
                    
                </TableRow>
            </TableHead>
            <TableBody>
                {categories.content.map(c => (
                    <TableRow key={c.id} hover onClick={() => onOpen(c)}>
                        {allowSelection && (
                            <TableCell padding="checkbox" onClick={e => {e.stopPropagation(); e.preventDefault()}}>
                                <Checkbox checked={categorySelected(c)} onChange={e => selectCategory(c, e.target.checked)}/>
                            </TableCell>
                        )}
                        <TableCell>{c.id}</TableCell>
                        <TableCell>{c.name}</TableCell>
                        <TableCell align="right">{c.version}</TableCell>
                    </TableRow>
                ))}
                {categories.totalElements == 0 && (
                    <EmptyTable title={messages.categories.none.title} info={messages.categories.none.info}
                                src={notFound} colSpan={colspan}
                    />
                )}
            </TableBody>
        </Table>
        );
}
