import * as React from "react";
import {createElement, useEffect, useState} from "react";
import {RouteComponentProps, useHistory} from "react-router";
import {ContainerLayout} from "../ContainerLayout";
import {Box, Breadcrumbs, Button, Grid, Paper, Typography, ButtonGroup} from "@material-ui/core";
import {gs} from "../../theme";
import {Crumb} from "../Crumb";
import {Inventory, InventoryState, Product} from "../../domain/types";
import {ProductsProvider} from "../../domain/ProductsProvider";
import {userStore} from "../../domain/UserStore";
import {LabeledData} from "../LabeledData";
import {ImageWithPopover} from "../ImageWithPopover";
import {VenditionProvider} from "../../domain/VenditionProvider";
import {InventoryTable, TableMode} from "./InventoryTable";
import {AvailabilityDisplay} from "../base/AvailabilityDisplay";
import {Loading} from "../Loading";
import {messages} from '../../i18n';
import { EditFab } from "../EditFab";

interface ProductViewProps extends RouteComponentProps<{id: string}>{
}

export function ProductView(props: ProductViewProps) {
    const history = useHistory();
    const [product, setProduct] = useState<Product>();
    const [inventory, setInventory] = useState<Inventory[]>();
    const [reserved, setReserved] = useState<Inventory[]>();
    const [sold, setSold] = useState<Inventory[]>();

    useEffect(() => {
        const productId = props.match.params.id;
        ProductsProvider.get(userStore.getTenantId(), productId).then(setProduct);
        VenditionProvider.inventory(userStore.getTenantId(), productId, InventoryState.Stored).then(setInventory);
        VenditionProvider.inventory(userStore.getTenantId(), productId, InventoryState.Reserved).then(setReserved);
        VenditionProvider.inventory(userStore.getTenantId(), productId, InventoryState.Sold).then(setSold);
    }, []);

    const edit = () => {
        history.push('/products/' + product?.id + '/edit');
    }

    return (
        <ContainerLayout>
            <Grid container spacing={gs}>
                <Grid item xs={8}>
                    <Breadcrumbs>
                        <Crumb label="Produkte" route="/products" />
                        <Crumb label={`Produkt: ${product ? product.name : '...'}`} />
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={4}>
                	<Typography align="right">
                        <ButtonGroup>
                            <Button onClick={history.goBack}>{messages.back}</Button>
                            <Button onClick={edit} variant="contained" color="primary">{messages.edit}</Button>
                        </ButtonGroup>
                    </Typography>
				</Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item style={{flexGrow: 1}}>
                            <Typography variant="h4" gutterBottom>{`Produkt: ${product ? product.name : '...'}`}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {!product && <Loading />}
                {product &&
                <React.Fragment>
                    <Grid item xs={12} md={8} sm={6}>
                        <Grid container spacing={gs} justify="flex-end">
                            <Grid item xs={12}>
                                <Paper>
                                    <Box p={2}>
                                        <Grid container spacing={gs}>
                                            <Grid item>
                                                <LabeledData label={messages.products.foreignId}>{product.foreignId}</LabeledData>
                                            </Grid>
                                            <Grid item>
                                                <LabeledData label={messages.products.name}>{product.name}</LabeledData>
                                            </Grid>
                                            <Grid item>
                                                <LabeledData label={messages.amount.net}>{product.price?new Intl.NumberFormat("de-AT", {style: 'currency',currency:'EUR'}).format(product.price.net):''}</LabeledData>
                                            </Grid>
                                            <Grid item>
                                                <LabeledData label={messages.amount.taxRate}>{product.price?product.price.taxRate+'%':''}</LabeledData>
                                            </Grid>
                                            <Grid item>
                                                <LabeledData label={messages.amount.tax}>{product.price?new Intl.NumberFormat("de-AT", {style: 'currency',currency:'EUR'}).format(product.price.tax):''}</LabeledData>
                                            </Grid>
                                            <Grid item>
                                                <LabeledData label={messages.amount.gross}>{product.price?new Intl.NumberFormat("de-AT", {style: 'currency',currency:'EUR'}).format(product.price.gross):''}</LabeledData>
                                            </Grid>
                                            <Grid item style={{flexGrow: 1}}>
                                                <LabeledData label={messages.products.inventoryLimit}>{product.inventoryLimit}</LabeledData>
                                            </Grid>
                                            <Grid item>
                                                {inventory &&
                                                <AvailabilityDisplay availability={inventory.length} inventoryLimit={product.inventoryLimit || 3} />
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <LabeledData label={messages.products.description}>{product.description}</LabeledData>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Grid>
                            
                            {inventory &&
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Typography variant="h5">Verfügbares Inventar</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <InventoryTable inventory={inventory} />
                                    </Paper>
                                </Grid>
                            </React.Fragment>
                            }
                            {(reserved && reserved.length > 0) &&
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Typography variant="h5">Reserviert (nicht abgeholt)</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <InventoryTable inventory={reserved} />
                                    </Paper>
                                </Grid>
                            </React.Fragment>
                            }
                            {(sold && sold.length > 0) &&
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Typography variant="h5">Verkauft</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <InventoryTable inventory={sold} tableMode={TableMode.Sold} />
                                    </Paper>
                                </Grid>
                            </React.Fragment>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} sm={6}>
                        <Paper>
                            <ImageWithPopover src={product.image} />
                        </Paper>
                    </Grid>
                </React.Fragment>
                }
            </Grid>
            <EditFab onClick={edit}/>
        </ContainerLayout>
    );
}
