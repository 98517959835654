import * as React from "react";
import {Box as CubeBox, Cube, Occupancy} from "../../domain/types";
import {useState, createElement, useEffect, Component} from "react";
import {
    Box, Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography
} from "@material-ui/core";
import {Loading} from "../Loading";
import {messages} from "../../i18n";
import {BlockSvg} from "../BlockSvg";
import treeSwing from "../../assets/undraw_tree_swing_646s.svg";
import {useHistory} from "react-router";
import {CubeBoxLockStateComponent} from "./CubeBoxLockStateComponent";
import {ApiError} from "../../Api";
import {ErrorPopup} from "../ErrorPopup";
import {CubesProvider} from "../../domain/CubesProvider";
import {userStore} from "../../domain/UserStore";
import {ProductLinkComponent} from "../ProductLinkComponent";

export const BoxesList = (props: { cube: Cube }) => {
    const [boxes, setBoxes] = useState<CubeBox[]|undefined>();
    const [error, setError] = useState<ApiError|undefined>(undefined);

    useEffect(() => {
        CubesProvider.listBoxes(userStore.getTenantId(), props.cube.cubeId)
            .then(setBoxes)
            .catch(toggleError)
    }, [setBoxes]);

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    return (
        <div>
            <Paper>
                {!boxes && (
                    <Box p={5}>
                        <Loading/>
                    </Box>
                )}
                {boxes && (
                    <BoxTable list={boxes} cube={props.cube} />
                )}
            </Paper>
            { error && (
                <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </div>
    );
};

type sortKeys = 'number' | 'types' | 'lockStatus' | 'disabled' | 'occupancies';

interface BoxTableProps {
    cube: Cube;
    list: CubeBox[];
}

interface BoxTablesStates {
    orderAsc: boolean;
    orderBy: sortKeys;
}

class BoxTable extends Component<BoxTableProps, BoxTablesStates> {

    constructor(props: any) {
        super(props);
        this.state = {
            orderAsc: true,
            orderBy: 'number'
        };
    }

    private onSort(sortKey: sortKeys) {
        if (this.state.orderBy === sortKey) {
            this.setState({
                orderAsc: !this.state.orderAsc
            });
        } else {
            this.setState({
                orderBy: sortKey
            });
        }
    }

    private getSorted() {
        const {list} = this.props;
        return list ? list.sort((a, b) => BoxTable.descendingComparator(a, b, this.state.orderAsc, this.state.orderBy)) : undefined;
    }

    private static descendingComparator(a: CubeBox, b: CubeBox, asc: boolean, orderBy: sortKeys): number {
        let aValue, bValue;
        switch (orderBy) {
            case "number":
                aValue = a.number;
                bValue = b.number;
                try {
                    const aInt = parseInt(a.number);
                    const bInt = parseInt(b.number);
                    if(asc) {
                        return aInt - bInt;
                    }else {
                        return bInt - aInt;
                    }
                } catch(e) {
                }
                break;
            case "types":
                aValue = a.types.sort((x, y) => x.localeCompare(y)).join(',');
                bValue = b.types.sort((x, y) => x.localeCompare(y)).join(',');
                break;
            case "lockStatus":
                aValue = a.lockStatus ? a.lockStatus : "0";
                bValue = b.lockStatus ? b.lockStatus : "0";
                break;
            case "disabled":
                aValue = a.disabled ? "1" : "0";
                bValue = b.disabled ? "1" : "0";
                break;
            case "occupancies":
                aValue = a.occupancies.length.toString();
                bValue = b.occupancies.length.toString();
                break
            default:
                throw new Error('You did not select a valid comparison field');
        }
        const v = aValue.localeCompare(bValue);
        if(asc) {
            return v;
        }
        return v * -1;
    }

    render() {
        const sorted = this.getSorted();
        const {orderAsc, orderBy} = this.state;
        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'number'}
                                direction={orderAsc ? 'asc' : 'desc'}
                                onClick={(e) => this.onSort('number')}
                            >
                                {messages.cubes.boxes.singular}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'types'}
                                direction={orderAsc ? 'asc' : 'desc'}
                                onClick={(e) => this.onSort('types')}
                            >
                                {messages.cubes.boxes.boxType}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'lockStatus'}
                                direction={orderAsc ? 'asc' : 'desc'}
                                onClick={(e) => this.onSort('lockStatus')}
                            >
                                {messages.cubes.boxes.lockState.label}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{flexGrow: 1}}>
                            <TableSortLabel
                                active={orderBy === 'occupancies'}
                                direction={orderAsc ? 'asc' : 'desc'}
                                onClick={(e) => this.onSort('occupancies')}
                            >
                                {messages.cubes.boxes.occupancy}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { sorted && sorted.length > 0 && sorted.map((box) => (
                        <BoxRow cube={this.props.cube} box={box} key={box.number} />
                    ))}
                    { sorted && sorted.length == 0 && (
                        <TableRow>
                            <TableCell colSpan={5}>
                                <Box p={6}>
                                    <Typography variant="h6" align="center">{messages.cubes.boxes.noBoxes}</Typography>
                                </Box>
                                <BlockSvg src={treeSwing} />
                                <Box p={6} />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        )
    }
}

const BoxRow = (props: { cube: Cube, box: CubeBox }) => {
    const history = useHistory();

    const {cube, box} = props;
    return (
        <TableRow style={{cursor: 'pointer', backgroundColor: box.disabled ? '#efefef' : undefined}} hover
                  onClick={() => history.push(`/cubes/${cube.cubeId}/boxes/${box.number}`)}>
            <TableCell style={{whiteSpace: 'nowrap'}}>{box.description}</TableCell>
            <TableCell><BoxTypes boxTypes={box.types} /></TableCell>
            <TableCell><CubeBoxLockStateComponent lockState={box.lockStatus} /></TableCell>
            <TableCell><BoxOccupancies occupancies={box.occupancies} /></TableCell>
        </TableRow>
    );
};

const BoxTypes = (props: { boxTypes: string[] }) => {
    const {boxTypes} = props;
    return (
        <div>
            {boxTypes.map((boxType) =>
                <Chip key={boxType} label={boxType} />
            )}
        </div>
    );
}

const BoxOccupancies = (props: { occupancies: Occupancy[] }) => {
    return (
        <div>
            {props.occupancies.map(o =>
                <ProductLinkComponent key={o.uuid} productId={o.productId} inventoryId={o.uuid} />
            )}
        </div>
    );
}
