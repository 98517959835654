import * as React from "react";
import {createElement, useCallback, useEffect, useState} from "react";
import {MenuItem, TextField} from "@material-ui/core";
import {CubesProvider} from "../../domain/CubesProvider";
import {SmallLoading} from "../Loading";
import {Cube} from "../../domain/types";
import {userStore} from "../../domain/UserStore";

interface CubeSelectInputProps {
    value?: Cube;
    label?: string;
    onChange: (cube: Cube) => void;
    autoSelect?: boolean;
}

export function CubeSelectInput({value, label, onChange, autoSelect}: CubeSelectInputProps) {
    const [cubes, setCubes] = useState<Cube[] | undefined>(undefined);

    useEffect(() => {
        CubesProvider.list(userStore.getTenantId()).then((page) => {
            setCubes(page.content);
        });
    }, [setCubes, autoSelect]);

    useEffect(() => {
        if(autoSelect && cubes && cubes.length == 1) {
            handleChange(cubes[0].cubeId);
        }
    }, [cubes, autoSelect]);

    const handleChange = useCallback((cubeId: string) => {
        if(cubes && cubeId) {
            const cube = cubes.find((c) => c.cubeId === cubeId);
            if(cube) {
                onChange(cube);
            }
        }
    }, [cubes]);

    const cubeId = value ? value.cubeId : '';

    if(cubes) {
        return (
            <TextField select fullWidth variant="outlined" label={label} value={cubeId} onChange={(e) => handleChange(e.target.value)}>
                {cubes.map((cube) =>
                    <MenuItem key={cube.cubeId} value={cube.cubeId}>{cube.description}</MenuItem>
                )}
            </TextField>
        );
    }
    return (<SmallLoading />);
}
