import {createElement, Fragment, useEffect, useState} from "react";
import {ContainerLayout} from "../ContainerLayout";
import {Box, Breadcrumbs, Button, Grid, Paper} from "@material-ui/core";
import {Loading} from "../Loading";
import {Cube, User} from "../../domain/types";
import {ApiError} from "../../Api";
import {UsersProvider} from "../../domain/UsersProvider";
import {userStore} from "../../domain/UserStore";
import {ErrorPopup} from "../ErrorPopup";
import {RouteComponentProps} from "react-router";
import {Crumb} from "../Crumb";
import {messages} from "../../i18n";
import {CubeSelectInput} from "../cubes/CubeSelectInput";

export const UserEdit = (props: RouteComponentProps<{ userId: string }>) => {
    const [user, setUser] = useState<User|undefined>(undefined);
    const [error, setError] = useState<ApiError|undefined>(undefined);

    const [cube, setCube] = useState<Cube>();

    useEffect(() => {
        UsersProvider.get(userStore.getTenantId(), props.match.params.userId)
            .then(setUser)
            .catch(toggleError);
    }, [setUser]);

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    function handleUserUpdate() {
        if (user) {
            UsersProvider.put(userStore.getTenantId(), user.uuid, { cubeId: cube?.cubeId })
                .then(() => props.history.push('/users/' + user.uuid))
                .catch(toggleError);
        }
    }

    return (
        <ContainerLayout>
            {!user && (
                <Box p={5}>
                    <Loading/>
                </Box>
            )}
            {user && (
                <Fragment>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Breadcrumbs>
                                <Crumb route="/users" label={messages.users.plural} />
                                <Crumb route={`/users/${props.match.params.userId}`} label={user.email} />
                                <Crumb label="Bearbeiten" />
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={2}>
                                    <CubeSelectInput value={cube} onChange={setCube} label={"Standort"} />
                                    <Box py={3} />
                                    <Button variant="contained" color="primary" onClick={handleUserUpdate}>Speichern</Button>
                                    <Button variant="contained" onClick={() => props.history.push('/users/' + user.uuid)}>Abbrechen</Button>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Fragment>
            )}
            { error && (
                <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </ContainerLayout>
    )
}