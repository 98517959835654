import * as React from "react";
import {createElement, Fragment, useCallback, useEffect, useState} from "react";
import {
    Breadcrumbs, Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    Typography
} from "@material-ui/core";
import {Crumb} from "../Crumb";
import {ContainerLayout} from "../ContainerLayout";
import {Cube, Page, Pageable, ProductInventory} from "../../domain/types";
import {VenditionProvider} from "../../domain/VenditionProvider";
import {userStore} from "../../domain/UserStore";
import {Loading} from "../Loading";
import {gs} from "../../theme";
import {paging} from "../../domain/paging";
import {useHistory} from "react-router";
import {AvailabilityDisplay} from "../base/AvailabilityDisplay";
import {CubesProvider} from "../../domain/CubesProvider";
import {CubeSelectInput} from "../cubes/CubeSelectInput";
import {makeStyles} from "@material-ui/core/styles";
import {InventoryProvider} from "../../domain/InventoryProvider";

const LAST_USED_CUBE_ID = 'vending-last-used-cube-id';

interface SelectAvailableProductProps {
}

export function SelectAvailableProduct(props: SelectAvailableProductProps) {
    const [pageable, setPageable] = useState<Pageable>({ ...paging.defaultPageable(), sort: 'name', direction: 'asc' });
    const [cubes, setCubes] = useState<Cube[]>();
    const [selectedCube, setSelectedCube] = useState<Cube>();
    const [availableProducts, setAvailableProducts] = useState<Page<ProductInventory>>();

    useEffect(() => {
        const tenantId = userStore.getTenantId();
        const ssignedCubeId = userStore.user?.cubeId;
        if (!ssignedCubeId) {
            CubesProvider.list(tenantId).then((page) => {
                setCubes(page.content);
            });
        } else {
            CubesProvider.get(tenantId, ssignedCubeId).then(setSelectedCube);
        }
    }, []);

    const handleSelectedCube = useCallback((cube: Cube) => {
        setSelectedCube(cube);
        localStorage.setItem(LAST_USED_CUBE_ID, cube.cubeId);
    }, [cubes]);

    useEffect(() => {
        if(cubes && cubes.length > 0 && !selectedCube) {
            const lastUsedCubeId = localStorage.getItem(LAST_USED_CUBE_ID);
            const lastUsedCube = cubes.filter((c) => c.cubeId == lastUsedCubeId).pop();
            if(lastUsedCube) {
                handleSelectedCube(lastUsedCube);
            }else {
                handleSelectedCube(cubes[0]);
            }
        }
    }, [cubes]);

    useEffect(() => {
        if(selectedCube) {
            VenditionProvider.cubeInventory(userStore.getTenantId(), selectedCube.cubeId, pageable).then(setAvailableProducts);
        }
    }, [selectedCube, pageable]);

    const handleCheckLowInventory = useCallback(() => {
        InventoryProvider.checkLowInventory(userStore.getTenantId())
            .then(() => console.log('Completed low inventory check'));
    }, []);

    return (
        <ContainerLayout>
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <Breadcrumbs>
                        <Crumb label={'Verfügbare Produkte'} />
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item style={{flexGrow: 1}}>
                            <Typography variant="h4" gutterBottom>{'Verfügbare Produkte'}</Typography>
                        </Grid>
                        {userStore.root &&
                            <Grid item>
                                <Button variant="outlined" onClick={handleCheckLowInventory}>Inventar prüfen</Button>
                            </Grid>
                        }
                        <Grid item>
                            {!userStore.user?.cubeId && <CubeSelectInput value={selectedCube} onChange={handleSelectedCube} label={"Standort"} />}
                            {(!!userStore.user?.cubeId && selectedCube) && (
                                <Fragment>
                                    <Typography variant="overline">Standort</Typography>
                                    <Typography>{selectedCube.description}</Typography>
                                </Fragment>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {!availableProducts &&
                <Grid item xs={12}>
                    <Loading />
                </Grid>
                }
                {availableProducts &&
                <Grid item xs={12}>
                    <Grid container spacing={gs}>
                        {availableProducts.content.map((product) =>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <AvailableProductCard key={product.product.id} product={product} />
                        </Grid>
                        )}
                    </Grid>
                </Grid>
                }
            </Grid>
        </ContainerLayout>
    );
}

interface AvailableProductCardProps {
    product: ProductInventory;
}

function AvailableProductCard(props: AvailableProductCardProps) {
    const history = useHistory();
    const product = props.product.product;
    const inventory = props.product.inventory;
    const availability = props.product.availability;

    const classes = useStyles();

    const handleVend = useCallback(() => {
        if(inventory.length > 0) {
            const inventoryId = inventory[0];
            history.push(`/vendition/${inventoryId}`);
        }
    }, [inventory])

    return (
        <Card>
            <CardMedia component="img" height="150" image={product.image} alt={product.name} className={classes.cardMedia} />
            <CardContent className={classes.cardContent}>
                <Grid container spacing={gs}>
                    <Grid item xs={12}>
                        <Grid container spacing={gs}>
                            <Grid item style={{flexGrow: 1}}>
                                <Typography variant="h5" component="div" className={classes.cardTitle}>{product.name}</Typography>
                            </Grid>
                            <Grid item>
                                <AvailabilityDisplay availability={availability} inventoryLimit={product.inventoryLimit || 3} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography gutterBottom variant="body2" color="textSecondary" component="div" className={classes.productDescription}>{product.description}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button color="primary" disabled={availability == 0} onClick={() => handleVend()}>Ausgeben</Button>
                <Button onClick={() => history.push(`/products/${product.id}`)}>Details</Button>
            </CardActions>
        </Card>
    );
}

const useStyles = makeStyles((theme) => ({
    cardMedia: {
        objectFit: 'contain',
        padding: '0.5em',
        height: '16em'
    },
    cardTitle: {
        paddingTop: '0.15em'
    },
    cardContent: {
        backgroundColor: theme.palette.background.default
    },
    productDescription: {
        maskImage: 'linear-gradient(to bottom, black 50%, transparent 100%)',
        overflowY: 'hidden',
        height: '8em'
    }
}));
