import {Avatar} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {deepOrange, green, orange, red} from "@material-ui/core/colors";
import {createElement} from "react";

interface AvailabilityDisplayProps {
    availability: number;
    inventoryLimit: number;
}

export function AvailabilityDisplay(props: AvailabilityDisplayProps) {
    const classes = useStyles();
    const {availability, inventoryLimit} = props;
    let classname = classes.low;
    if(availability > 0) {
        classname = classes.medium;
    }
    if(availability >= inventoryLimit) {
        classname = classes.full;
    }
    return (<Avatar className={classname}>{availability}</Avatar>);
}

const useStyles = makeStyles((theme) => ({
    low: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
    },
    medium: {
        color: theme.palette.getContrastText(orange[500]),
        backgroundColor: orange[500],
    },
    full: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
    }
}));
