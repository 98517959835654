import * as React from "react";
import {createElement, useState} from "react";
import {Cube} from "../../domain/types";
import {gs} from "../../theme";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Typography
} from "@material-ui/core";
import {messages} from "../../i18n";
import {LabeledData} from "../LabeledData";
import {SettingsIcon} from "../../icons";
import {CubesProvider} from "../../domain/CubesProvider";
import {userStore} from "../../domain/UserStore";

interface CubeAccessCodesComponentProps {
    cube: Cube;
    onChanged: () => void;
}

export function CubeAccessCodesComponent({cube, onChanged}: CubeAccessCodesComponentProps) {
    const {accessCodes} = cube;

    const [updateCarrierAccessCode, setUpdateCarrierAccessCode] = useState<boolean>(false);
    const [carrierId, setCarrierId] = useState<string>('');
    const [accessCode, setAccessCode] = useState<string>('');
    const [pin, setPin] = useState<string>('');

    const handleUpdateCode = () => {
        CubesProvider.setCarrierAccessCode(userStore.getTenantId(), cube.cubeId, carrierId, accessCode, pin)
            .then(() => {
                setUpdateCarrierAccessCode(false);
                onChanged();
            });
    };

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <Grid container spacing={gs}>
                    <Grid item style={{flexGrow: 1}}>
                        <Typography variant="subtitle1"><strong>{messages.cubes.accessCodes.title}</strong></Typography>
                    </Grid>
                    <Grid item>
                        <IconButton size="small" onClick={() => setUpdateCarrierAccessCode(true)}><SettingsIcon /></IconButton>
                    </Grid>
                </Grid>
            </Grid>
            {accessCodes.carrier &&
                <React.Fragment>
                    {Object.keys(accessCodes.carrier).map((key: keyof typeof accessCodes.carrier) =>
                    <Grid key={key} item xs={12} sm={6}>
                        <LabeledData label={`${messages.cubes.accessCodes.carrier}: ${key}`}>
                            <Typography variant="body1">{`${accessCodes.carrier[key].accessCode}: ${accessCodes.carrier[key].pin}`}</Typography>
                        </LabeledData>
                    </Grid>
                    )}
                </React.Fragment>
            }
            {(accessCodes.carrier == undefined) &&
            <Grid item xs={12} sm={6}>
                <LabeledData label={messages.cubes.accessCodes.carrier}>
                    <Typography variant="body1">{messages.cubes.accessCodes.carrierNone}</Typography>
                </LabeledData>
            </Grid>
            }
            <Grid item xs={12} sm={6}>
                <LabeledData label={messages.cubes.accessCodes.readOnly}>
                    <Typography variant="body1">{accessCodes.readOnly}</Typography>
                </LabeledData>
            </Grid>
            <Grid item xs={12} sm={6}>
                <LabeledData label={messages.cubes.accessCodes.nonOccupied}>
                    <Typography variant="body1">{accessCodes.openNonOccupied}</Typography>
                </LabeledData>
            </Grid>
            <Grid item xs={12} sm={6}>
                <LabeledData label={messages.cubes.accessCodes.fullAccess}>
                    <Typography variant="body1">{accessCodes.fullAccess}</Typography>
                </LabeledData>
            </Grid>
            <Dialog open={updateCarrierAccessCode} onClose={() => setUpdateCarrierAccessCode(false)} maxWidth="md">
                <DialogTitle>{messages.cubes.accessCodes.carrierAccessCode.title}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={gs}>
                        <Grid item xs={12}>
                            <TextField variant="outlined" fullWidth
                                       value={carrierId} onChange={(e) => setCarrierId(e.target.value)}
                                       label={messages.cubes.accessCodes.carrierAccessCode.carrierId} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant="outlined" fullWidth
                                       value={accessCode} onChange={(e) => setAccessCode(e.target.value)}
                                       label={messages.cubes.accessCodes.carrierAccessCode.accessCode} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant="outlined" fullWidth
                                       value={pin} onChange={(e) => setPin(e.target.value)}
                                       label={messages.cubes.accessCodes.carrierAccessCode.pin} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUpdateCarrierAccessCode(false)}>{messages.cancel}</Button>
                    <Button color="primary" disabled={accessCode.length == 0} onClick={() => handleUpdateCode()}>{messages.save}</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
