import * as React from "react";
import {createElement, useEffect, useState} from "react";
import {gs} from "../../theme";
import {Box, 
    Breadcrumbs, 
    Grid, 
    LinearProgress, 
    Paper, 
    TableContainer, 
    Toolbar, 
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button} from "@material-ui/core";
import {Crumb} from "../Crumb";
import {ContainerLayout} from "../ContainerLayout";
import {Page, Pageable, Product, ProductCreateRequest, Category} from "../../domain/types";
import {ProductsProvider} from "../../domain/ProductsProvider";
import {paging} from "../../domain/paging";
import {ErrorPopup} from "../ErrorPopup";
import {AddFab} from "../AddFab";
import {userStore} from "../../domain/UserStore";
import {ApiError} from "../../Api";
import {Loading} from "../Loading";
import {TableColumnFilter} from "../base/TableColumnFilter";
import {TablePagePagination} from "../base/TablePagePagination";
import {availableColumns, defaultColumns, ProductsTable} from "./ProductsTable";
import {RouteComponentProps} from "react-router";
import {messages} from "../../i18n";
import { Autocomplete } from '@material-ui/lab';
import { CategoriesProvider } from '../../domain/CategoriesProvider';

interface ProductsListProps extends RouteComponentProps {
}

export function ProductsList(props: ProductsListProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const [pageable, setPageable] = useState<Pageable>({ ...paging.defaultPageable(), sort: 'name', direction: 'asc' });
    const [products, setProducts] = useState<Page<Product>>();
    const [unpaged, setUnpaged] = useState<Pageable>({ ...paging.defaultPageable(), sort: 'name', direction: 'asc', unpaged: true });
    const [categories, setCategories] = useState<Page<Category>>();
    const [categoriesLoading, setCategoriesLoading] = useState<boolean>(false);
    const [error, setError] = useState<ApiError|undefined>(undefined);
    const [selectedColumns, setSelectedColumns] = useState<string[]>(defaultColumns);
    const [createMode, setCreateMode] = useState<boolean>(false);
    const [createForm, setCreateForm] = useState<ProductCreateRequest>({
        id:'',
        foreignId:'',
        name: '',
        image: '',
        price: {
            net: 0,
            taxRate: 20,
            tax: 0,
            gross: 0
        },
        category: {} as Category 
	});

    const load = (p: Pageable) => {
        setLoading(true);
        ProductsProvider.list(userStore.getTenantId(), p)
            .then((page) => {
                setProducts(page);
                setLoading(false);
            })
            .catch(toggleError);
    };

    useEffect(() => {
        load(pageable);
        loadCategories(unpaged);
    }, []);

   

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    const handleSelectedColumns = (columns: string[]) => {
        setSelectedColumns(columns);
    }

    const handleSortTableChange = (sort: string, direction: 'asc' | 'desc') => {
        const p = {
            ...pageable,
            sort,
            direction
        };
        setPageable(p);
        load(p);
    }

    const toggleCreateMode = () => {
        setCreateMode(!createMode);
    };

    const round = (val:number) => {
        return Math.round(val*100)/100;
    }

    const onInputChange = (event: any) => {
        const {name, value} = event.target;
        console.log(name);
        console.log(value);

        let val = parseFloat(value);
        if(isNaN(val)) val = 0;
        console.log(val);

        if(name == 'net'){
            let gross = round(val + (val*createForm.price.taxRate/100))
            setCreateForm({
                ...createForm,
                price: {
                    net: value,
                    taxRate: createForm.price.taxRate,
                    tax: round(gross-val),
                    gross: gross
                }
                // price: {} tax: (val*createForm.taxRate/100),
                // gross: (val + (val*createForm.taxRate/100))
            })
        } else if(name == 'taxRate') {
            let gross = round((Number(createForm.price.net) + (createForm.price.net*val/100)))
            setCreateForm({
                ...createForm,
                price: {
                    net: createForm.price.net,
                    taxRate: value,
                    tax: round(gross-createForm.price.net),
                    gross: gross
                }
                
                // tax: (createForm.net*val/100),
                // gross: (createForm.net + (createForm.net*val/100))
            })
        } else if(name == 'gross'){
            let net = round(val/(1+createForm.price.taxRate/100));
            setCreateForm({
                ...createForm,
                price: {
                    net: net,
                    taxRate: createForm.price.taxRate,
                    tax: round(val-net),
                    gross: value
                }
                // net: (val/(1+createForm.taxRate/100)),
                // tax: (createForm.net*createForm.taxRate/100)
            })
        } else {
            setCreateForm({
                    ...createForm,
                    [name]: value
                });
        }

    };

     const loadCategories = (p: Pageable) =>  {
        setCategoriesLoading(true);
        CategoriesProvider.list(userStore.getTenantId(), p)
            .then((page) => {
                setCategories(page);
                setCategoriesLoading(false);
            })
            .catch(toggleError);
     }
    
    const create = () => {
        ProductsProvider.create(userStore.getTenantId(), createForm)
            .then(c => props.history.push(`/products/${c.id}`))
            .catch(toggleError);
    };
    
    
    

    return (
        <ContainerLayout>
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <Breadcrumbs>
                        <Crumb label="Produkte" />
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item style={{flexGrow: 1}}>
                            <Typography variant="h4" gutterBottom>{messages.products.plural}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        {products == undefined && (
                            <Box p={3}>
                                <Box p={1} />
                                <Loading />
                            </Box>
                        )}
                        {products != undefined &&
                        <React.Fragment>
                            <Toolbar>
                                <Grid container spacing={gs}>
                                    <Grid item>
                                        <TableColumnFilter availableColumns={availableColumns} selectedColumns={selectedColumns}
                                                           onChange={(columns) => handleSelectedColumns(columns)} />
                                    </Grid>
                                </Grid>
                            </Toolbar>
                            <TableContainer>
                                <ProductsTable products={products} columns={selectedColumns as any}
                                                 onOpen={d => props.history.push('/products/' + d.id)}
                                                 onSortChange={handleSortTableChange}
                                />
                            </TableContainer>
                            <TablePagePagination pageable={pageable} page={products} onPagingChanged={p => load(p)} />
                            {loading &&
                            <LinearProgress variant="indeterminate" />
                            }
                        </React.Fragment>
                        }
                    </Paper>
                </Grid>
            </Grid>
            <AddFab onClick={toggleCreateMode} />
            <Dialog open={createMode} fullWidth maxWidth="sm">
            <DialogTitle>{messages.products.actions.add}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField fullWidth variant="outlined"
                                        name="name"
                                        value={createForm.name}
                                        onChange={onInputChange}
                                        label={messages.products.name}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth variant="outlined"
                                        name="foreignId"
                                        value={createForm.foreignId}
                                        onChange={onInputChange}
                                        label={messages.products.foreignId}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField fullWidth
                                        name="net"
                                        value={createForm.price.net}
                                        onChange={onInputChange}
                                        label={messages.amount.net}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField fullWidth
                                        name="taxRate"
                                        value={createForm.price.taxRate}
                                        onChange={onInputChange}
                                        label={messages.amount.taxRate}/>
                            
                        </Grid>
                        <Grid item xs={3}>
                            <TextField fullWidth
                                        disabled
                                        name="tax"
                                        value={createForm.price.tax}
                                        onChange={onInputChange}
                                        label={messages.amount.tax}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField fullWidth
                                        name="gross"
                                        value={createForm.price.gross}
                                        onChange={onInputChange}
                                        label={messages.amount.gross}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth variant="outlined"
                                        name="image"
                                        value={createForm.image}
                                        onChange={onInputChange}
                                        label={messages.products.image}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete id='category'
                                        autoSelect
                                        handleHomeEndKeys
                                        fullWidth
                                        openOnFocus
                                        loading={categoriesLoading}
                                        loadingText={messages.loading}
                                        options={categories?categories.content:[]}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        onChange={(e, selectedObject: Category|null) => {
                                            if (selectedObject !== null){
                                                setCreateForm({
                                                ...createForm,
                                                category: selectedObject})
                                            }
                                        }}
                                        getOptionLabel={(option: Category) => (option.name)}
                                        renderInput={(params) => <TextField {...params} label={messages.products.category} variant="outlined"/>}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleCreateMode}>{messages.cancel}</Button>
                    <Button variant="contained" color="primary" onClick={create}>{messages.create}</Button>
                </DialogActions>
            </Dialog>
           { error && (
                <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </ContainerLayout>
    );
}
