import * as React from "react";
import {createElement, useEffect, useState} from "react";
import {gs} from "../../theme";
import {Box, 
    Breadcrumbs, 
    Grid, 
    LinearProgress, 
    Paper, 
    TableContainer, 
    Toolbar, 
    Typography 
    } from "@material-ui/core";
import {Crumb} from "../Crumb";
import {ContainerLayout} from "../ContainerLayout";
import {Page, Pageable, Invoice} from "../../domain/types";
import {InvoicesProvider} from "../../domain/InvoicesProvider";
import {paging} from "../../domain/paging";
import {ErrorPopup} from "../ErrorPopup";
import {userStore} from "../../domain/UserStore";
import {ApiError} from "../../Api";
import {Loading} from "../Loading";
import {TableColumnFilter} from "../base/TableColumnFilter";
import {TablePagePagination} from "../base/TablePagePagination";
import {availableColumns, defaultColumns, InvoicesTable} from "./InvoicesTable";
import {RouteComponentProps} from "react-router";
import {messages} from "../../i18n";

interface InvoicesListProps extends RouteComponentProps {
}

export function InvoicesList(props: InvoicesListProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const [pageable, setPageable] = useState<Pageable>({ ...paging.defaultPageable(), sort: 'createdAt', direction: 'asc' });
    const [invoices, setInvoices] = useState<Page<Invoice>>();
    const [error, setError] = useState<ApiError|undefined>(undefined);
    const [selectedColumns, setSelectedColumns] = useState<string[]>(defaultColumns);

    const load = (p: Pageable) => {
        setLoading(true);
        InvoicesProvider.list(userStore.getTenantId(), p)
            .then((page) => {
                setInvoices(page);
                setLoading(false);
            })
            .catch(toggleError);
    };

    useEffect(() => {
        load(pageable);
    }, []);

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    const handleSelectedColumns = (columns: string[]) => {
        setSelectedColumns(columns);
    }

    const handleSortTableChange = (sort: string, direction: 'asc' | 'desc') => {
        const p = {
            ...pageable,
            sort,
            direction
        };
        setPageable(p);
        load(p);
    }


    return (
        <ContainerLayout>
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <Breadcrumbs>
                        <Crumb label={messages.invoices.plural} />
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item style={{flexGrow: 1}}>
                            <Typography variant="h4" gutterBottom>{messages.invoices.plural}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        {invoices == undefined && (
                            <Box p={3}>
                                <Box p={1} />
                                <Loading />
                            </Box>
                        )}
                        {invoices != undefined &&
                        <React.Fragment>
                            <Toolbar>
                                <Grid container spacing={gs}>
                                    <Grid item>
                                        <TableColumnFilter availableColumns={availableColumns} selectedColumns={selectedColumns}
                                                           onChange={(columns) => handleSelectedColumns(columns)} />
                                    </Grid>
                                </Grid>
                            </Toolbar>
                            <TableContainer>
                                <InvoicesTable invoices={invoices} columns={selectedColumns as any}
                                                 onOpen={d => props.history.push('/invoices/' + d.id)}
                                                 onSortChange={handleSortTableChange}
                                />
                            </TableContainer>
                            <TablePagePagination pageable={pageable} page={invoices} onPagingChanged={p => load(p)} />
                            {loading &&
                            <LinearProgress variant="indeterminate" />
                            }
                        </React.Fragment>
                        }
                    </Paper>
                </Grid>
            </Grid>

            { error && (
                <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </ContainerLayout>
    );
}
