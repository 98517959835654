import React from 'react';
import {createElement, useState} from "react";
import {useHistory} from "react-router";
import {
    Button,
    Toolbar,
    Typography,
    Hidden,
    AppBar,
    Box,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem, ListItemIcon, ListItemText
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {VCLogo} from "./VCLogo";
import {messages} from "../i18n";
import {Category, Close, Dashboard, ExpandLess, ExpandMore, Menu, Person, ShoppingBasket, EuroSymbol, Settings} from "@material-ui/icons";
import {gs} from "../theme";
import {userStore} from "../domain/UserStore";

export const NavBar = () => {
    const history = useHistory();
    const classes = useStyles();
    const [showDrawer, setShowDrawer] = useState<boolean>(false);

    const navigate = (path: string) => {
        history.push(path);
        setShowDrawer(false);
    };


    return (
        <AppBar position="static" className={classes.appBar}>
            <Box display="block" displayPrint="none">
                <Toolbar>
                    <Button onClick={() => setShowDrawer(!showDrawer)}><Menu /></Button>
                    <VCLogo />
                    <Hidden smDown>
                        <Typography variant="h5" color="inherit" className={classes.navHome}
                                    onClick={() => navigate('/')}>{`${messages.appName}`}</Typography>
                    </Hidden>
                    <Button color="secondary" onClick={() => navigate('/vendition')}>Verkaufen</Button>
                    <Hidden mdUp>
                        <Typography color="inherit" style={{flexGrow: 1}}/>
                    </Hidden>
                </Toolbar>
                <Drawer open={showDrawer} onClose={() => setShowDrawer(false)}>
                    <Grid container spacing={gs}>
                        <Grid item style={{flexGrow: 1}}>
                            <Box paddingTop={2}>
                                <Typography variant="h5" color="inherit" className={classes.navHome}
                                            onClick={() => navigate('/')}>{`${messages.appName}`}</Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box paddingTop={1}>
                                <IconButton size="medium" onClick={() => setShowDrawer(false)}>
                                    <Close />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <List>
                        <NavMenuItem primary={messages.products.plural} icon={<ShoppingBasket />} onClick={() => navigate('/products')} />
                        <NavMenuItem primary={messages.categories.plural} icon={<Category />} onClick={() => navigate('/categories')} />
                        <NavMenuItem primary={messages.cubes.plural} icon={<Dashboard />} onClick={() => navigate('/cubes')} />
                        <NavMenuItem primary={messages.users.plural} icon={<Person />} onClick={() => navigate('/users')} />
                        <NavMenuItem primary={messages.invoices.plural} icon={<EuroSymbol />} onClick={() => navigate('/invoices')} />
                        {!userStore.root && (
                            <NavMenuItem primary={messages.settings.title} icon={<Settings />} onClick={() => navigate('/settings')} />
                        )}
                    </List>
                </Drawer>
            </Box>
        </AppBar>
    );
};

interface NavMenuItemProps {
    primary: string;
    secondary?: string;
    icon: React.ReactNode;
    onClick: () => void;
    collapse?: boolean;
    open?: boolean;
    nested?: boolean;
}

function NavMenuItem({primary, secondary, icon, onClick, collapse, open, nested}: NavMenuItemProps) {
    const classes = useStyles();
    return(
        <ListItem button onClick={onClick} className={nested ? classes.nested : undefined}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={primary} secondary={secondary} />
            {collapse &&
            <React.Fragment>
                {open ? <ExpandLess /> : <ExpandMore />}
            </React.Fragment>
            }
        </ListItem>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: 5000,
        backgroundColor: '#ffffff'
    },
    navHome: {
        flexGrow: 1,
        color: '#05164d',
        fontWeight: 'bold',
        paddingLeft: '0.4em'
    },
    nested: {
        paddingLeft: theme.spacing(4)
    },
}));
