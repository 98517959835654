import {createElement, Fragment, useEffect, useState} from "react";
import {ContainerLayout} from "../ContainerLayout";
import {Page, Pageable, User, UserCreateRequest, UserRole} from "../../domain/types";
import {ApiError} from "../../Api";
import {UsersProvider} from "../../domain/UsersProvider";
import {paging} from "../../domain/paging";
import {
    Box,
    Breadcrumbs, Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel, LinearProgress, MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
    TableSortLabel, TextField,
    Typography
} from "@material-ui/core";
import {Crumb} from "../Crumb";
import {messages} from "../../i18n";
import {Loading} from "../Loading";
import {AddFab} from "../AddFab";
import {ErrorPopup} from "../ErrorPopup";
import {RouteComponentProps, useHistory} from "react-router";
import {SortableTable} from "../SortableTable";
import {UserRoleDisplayComponent} from "./UserRoleDisplayComponent";
import {BlockSvg} from "../BlockSvg";
import treeSwing from "../../assets/undraw_tree_swing_646s.svg";
import {userStore} from "../../domain/UserStore";
import {TablePagePagination} from "../base/TablePagePagination";
import React from 'react';

export const UsersList = (props: RouteComponentProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [users, setUsers] = useState<Page<User>|undefined>(undefined);
    const [createForm, setCreateForm] = useState<UserCreateRequest>({
        email: '',
        role: UserRole.User
    });
    const [createMode, setCreateMode] = useState<boolean>(false);
    const [error, setError] = useState<ApiError|undefined>(undefined);

    const load = (pageable: Pageable) => {
        setLoading(true);
        UsersProvider.list(userStore.getTenantId(), pageable)
            .then((page) => {
                setUsers(page);
                setLoading(false);
            })
            .catch(toggleError);
    };

    useEffect(() => {
        load(paging.defaultPageable());
    }, [setUsers]);

    const toggleCreateMode = () => {
        setCreateMode(!createMode);
    };

    const onInputChange = (event: any) => {
        const {name, value} = event.target;
        setCreateForm({
            ...createForm,
            [name]: value
        });
    };

    const create = () => {
        UsersProvider.create(userStore.getTenantId(), createForm)
            .then(u => props.history.push(`/users/${u.uuid}`))
            .catch(toggleError);
    };

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    return (
        <ContainerLayout>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs>
                        <Crumb label={messages.users.plural} />
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item style={{flexGrow: 1}}>
                            <Typography variant="h4" gutterBottom>{messages.users.plural}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        {users == undefined && (
                            <Box p={3}>
                                <Box p={1} />
                                <Loading />
                            </Box>
                        )}
                        {users != undefined &&
                        <React.Fragment>
                            <TableContainer>
                                <UserTable list={users.content} />
                            </TableContainer>
                            <TablePagePagination page={users} onPagingChanged={load} />
                            {loading &&
                            <LinearProgress variant="indeterminate" />
                            }
                        </React.Fragment>
                        }
                    </Paper>
                </Grid>
            </Grid>
            {userStore.admin && (
                <Fragment>
                    <AddFab onClick={toggleCreateMode} />
                    <Dialog open={createMode} fullWidth maxWidth="sm">
                        <DialogTitle>{messages.users.actions.add}</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel>{messages.users.role}</InputLabel>
                                        <Select name="role"
                                                value={createForm.role}
                                                onChange={onInputChange}
                                                label={messages.users.role}
                                        >
                                            <MenuItem value={''}>{messages.users.selectRole}</MenuItem>
                                            {Object.values(UserRole).map(r => <MenuItem key={r} value={r}>{r}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth variant="outlined"
                                               name="email"
                                               value={createForm.email}
                                               onChange={onInputChange}
                                               label={messages.users.email}/>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={toggleCreateMode}>{messages.cancel}</Button>
                            <Button variant="contained" color="primary" onClick={create}>{messages.create}</Button>
                        </DialogActions>
                    </Dialog>
                </Fragment>
            )}

            { error && (
                <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </ContainerLayout>
    )
};

type sortKey = 'email' | 'role';

class UserTable extends SortableTable<User, sortKey> {

    constructor(props: any) {
        super(props);
        this.state = {
            orderAsc: true,
            orderBy: 'email'
        }
    }

    protected descendingComparator(a: User, b: User, asc: boolean, orderBy: sortKey) {
        let aValue, bValue;
        switch (orderBy) {
            case 'email':
                aValue = a.email;
                bValue = b.email;
                break;
            case 'role':
                aValue = a.role;
                bValue = b.role;
                break;
            default:
                throw new Error('You did not select a valid comparison field');
        }
        const v = (aValue || '').localeCompare(bValue || '');
        if (asc) {
            return v;
        }
        return v * -1;
    }

    render() {
        const {orderAsc, orderBy} = this.state;
        const sorted = this.getSorted();
        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: 130 }}>
                            <TableSortLabel active={orderBy === 'role'}
                                            direction={orderAsc ? 'asc' : 'desc'}
                                            onClick={() => this.onSort('role')}>
                                {messages.users.role}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === 'email'}
                                            direction={orderAsc ? 'asc' : 'desc'}
                                            onClick={() => this.onSort('email')}>
                                {messages.users.email}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(sorted && sorted.length > 0) && sorted.map(user => (
                        <UserRow user={user} key={user.username} />
                    ))}
                    { sorted && sorted.length == 0 && (
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Box p={6}>
                                    <Typography variant="h6" align="center">{messages.users.noUsers}</Typography>
                                </Box>
                                <BlockSvg src={treeSwing} />
                                <Box p={6} />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        )
    }
}

const UserRow = (props: { user: User }) => {
    const history = useHistory();
    const {user} = props;
    return (
        <TableRow hover style={{ cursor: 'pointer' }} onClick={() => history.push(`/users/${user.uuid}`)}>
            <TableCell style={{ width: 130 }}><UserRoleDisplayComponent role={user.role} /></TableCell>
            <TableCell>{user.email}</TableCell>
        </TableRow>
    );
};
