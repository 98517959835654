import * as React from "react";
import {createElement} from "react";
import {Inventory} from "../../domain/types";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {DateComponent} from "../DateComponent";
import {useHistory} from "react-router";
import {makeStyles} from "@material-ui/core/styles";
import {CubeDisplay} from "../cubes/CubeDisplay";

export enum TableMode {
    Inventory = "Inventory",
    Sold = "Sold",
}

interface InventoryTableProps {
    inventory: Inventory[];
    tableMode?: TableMode;
}

export function InventoryTable(props: InventoryTableProps) {
    const history = useHistory();
    const classes = useStyles();
    const {inventory} = props;
    const tableMode = props.tableMode || TableMode.Inventory;
    return (
        <Table stickyHeader size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Cube</TableCell>
                    <TableCell>Fach</TableCell>
                    <TableCell>Eingelagert</TableCell>
                    {tableMode == TableMode.Inventory &&
                    <TableCell align="center">Abgabecode</TableCell>
                    }
                    {tableMode == TableMode.Sold &&
                    <TableCell>Abgabezeitp.</TableCell>
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {inventory.map((i) =>
                <TableRow key={i.id} hover className={classes.clickableRow} onClick={() => history.push(`/vendition/${i.id}`)}>
                    <TableCell><CubeDisplay cubeId={i.cubeId} /></TableCell>
                    <TableCell>{i.boxNumber}</TableCell>
                    <TableCell><DateComponent date={i.createdAt} /></TableCell>
                    {tableMode == TableMode.Inventory &&
                    <TableCell align="center">{i.accessCode}</TableCell>
                    }
                    {tableMode == TableMode.Sold &&
                    <TableCell>{i.soldAt && <DateComponent date={i.soldAt} />}</TableCell>
                    }
                </TableRow>
                )}
            </TableBody>
        </Table>
    );
}

const useStyles = makeStyles((theme) => ({
    clickableRow: {
        cursor: 'pointer'
    }
}));
