import {createElement, useState} from 'react'
import {Box, Button, Grid, TextField, Paper} from "@material-ui/core";
import {ContainerLayout} from "./ContainerLayout";
import {messages} from "../i18n";
import {ChangePasswordRequest} from "../domain/types";
import {userStore} from "../domain/UserStore";
import {UsersProvider} from "../domain/UsersProvider";
import {Alert} from "@material-ui/lab";
import {gs} from "../theme";


export const SettingView = () => {
    const [passwordForm, setPasswordForm] = useState<ChangePasswordRequest>({
        password: '',
        confirmPassword: ''
    });
    const [success, setSuccess] = useState(false);
    const [formError, setFormError] = useState<'unmatched'|'weak'|'server'>();

    const handleChangePassword = () => {
        setSuccess(false);
        if (passwordForm.password !== passwordForm.confirmPassword) {
            setFormError('unmatched');
            return;
        }
        if (!/^(?=.*[A-Z])(?=.*[!@#$%^&*()\-_+.])(?=.*[0-9]).{8,}$/g.test(passwordForm.password)) {
            setFormError('weak');
            return;
        }
        UsersProvider.changePassword(userStore.getTenantId(), passwordForm)
            .then(() => {
                setPasswordForm({
                    password: '',
                    confirmPassword: ''
                });
                setSuccess(true);
                setFormError(undefined);
                setTimeout(() => {
                    setSuccess(false);
                }, 1500)
            })
            .catch(() => {
                setFormError('server');
            });
    }

    return (
        <ContainerLayout>
            <Paper>
                <Box p={gs}>
                    <Grid container spacing={3}>
                        <Grid item>
                            <TextField value={passwordForm.password} name="password"
                                       size="small" variant="outlined" type="password"
                                       label={messages.settings.password}
                                       onChange={ev => setPasswordForm({ ...passwordForm, password: ev.target.value })} />
                        </Grid>
                        <Grid item>
                            <TextField value={passwordForm.confirmPassword} name="confirmPassword"
                                       size="small" variant="outlined" type="password"
                                       label={messages.settings.confirmPassword}
                                       onChange={ev => setPasswordForm({ ...passwordForm, confirmPassword: ev.target.value })} />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary"
                                    disabled={!passwordForm.password || !passwordForm.confirmPassword}
                                    onClick={handleChangePassword}>{messages.settings.changePassword}</Button>
                        </Grid>
                    </Grid>
                    {formError && (
                        <Box mt={1}>
                            <Alert severity="error">{messages.settings.invalidPassword}</Alert>
                        </Box>
                    )}
                    {success && (
                        <Box mt={1}>
                            <Alert severity="success">{messages.settings.passwordChanged}</Alert>
                        </Box>
                    )}
                </Box>
            </Paper>
        </ContainerLayout>

    )
}