import * as React from "react";
import {createElement} from "react";
import {Backdrop, Dialog, Popover} from "@material-ui/core";

interface ImagePopoverProps {
    open?: boolean;
    onClose: () => void;
    src: string;
    alt?: string;
}

export function ImagePopover(props: ImagePopoverProps) {
    const {open, onClose, src, alt} = props;
    return (
        <Dialog open={open || false} onClose={() => onClose()} maxWidth="lg">
            <img src={src} alt={alt} onClick={() => onClose()} />
        </Dialog>
    );
}
