import * as React from "react";
import {createElement} from "react";
import {Container, Grid, Typography} from "@material-ui/core";
import {Inventory} from "../../domain/types";
import {gs} from "../../theme";
import {makeStyles} from "@material-ui/core/styles";
import {LabeledData} from "../LabeledData";
import {userStore} from "../../domain/UserStore";
import {DateComponent, Display} from "../DateComponent";
import {DateTime} from "luxon";
import {CubeDisplay} from "../cubes/CubeDisplay";

interface PickupSlipProps {
    inventory: Inventory;
}

export function PickupSlip(props: PickupSlipProps) {
    const {inventory} = props;
    const tenant = userStore.tenant;
    const user = userStore.user;
    const classes = useStyles();
    return (
        <Container maxWidth="sm" className={classes.noPadding}>
            <Grid container spacing={gs} className={classes.pickupSlipContainer}>
                <Grid item xs={12}>
                    <Typography align="center">
                        <Typography variant="overline">
                        Ihr Abholcode
                        </Typography>
                    </Typography>
                    <Typography variant="h1" align="center">{inventory.accessCode}</Typography>
                </Grid>
                <Grid item xs={12} className={classes.pickupSlipDivider}>
                    <LabeledData label="Produkt">
                        <Typography variant="body1" gutterBottom>{inventory.product.name}</Typography>
                        <Typography variant="body2" className={classes.preformattedText}>{inventory.product.description}</Typography>
                    </LabeledData>
                </Grid>
                <Grid item xs={12} className={classes.pickupSlipDivider}>
                    <LabeledData label="Standort">
                        <Typography variant="body1" gutterBottom>
                            <CubeDisplay cubeId={inventory.cubeId} />
                        </Typography>
                    </LabeledData>
                </Grid>
                {tenant?.disclaimer &&
                <Grid item xs={12} className={classes.pickupSlipDivider}>
                    <LabeledData label="Hinweise">
                        <Typography variant="body2" className={classes.preformattedText}>{tenant.disclaimer}</Typography>
                    </LabeledData>
                </Grid>
                }
                {tenant &&
                <Grid item xs={12} className={classes.pickupSlipDivider}>
                    <Grid container spacing={gs}>
                        <Grid item style={{flexGrow: 1}}>
                            <img src={tenant.logo} />
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">
                                {tenant.address.street} {tenant.address.houseNo}
                            </Typography>
                            <Typography variant="body2">
                                {tenant.address.zip} {tenant.address.city}
                            </Typography>
                            <Typography variant="body2">
                                {tenant.address.country}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                }
                <Grid item xs={12} className={classes.pickupSlipDivider}>
                    <Grid container spacing={gs}>
                        <Grid item style={{flexGrow: 1}}>
                            {user &&
                            <LabeledData label="Erzeugt durch">
                                <Typography variant="body2">{user.email || user.username}</Typography>
                            </LabeledData>
                            }
                        </Grid>
                        <Grid item>
                            <LabeledData label="Gedruckt am">
                                <Typography variant="body2">
                                    <DateComponent date={DateTime.local()} display={Display.Timestamp} />
                                </Typography>
                            </LabeledData>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

const useStyles = makeStyles((theme) => ({
    pickupSlipContainer: {
        border: '1px solid #efefef'
    },
    pickupSlipDivider: {
        borderTop: '1px solid #efefef'
    },
    preformattedText: {
        whiteSpace: 'pre-wrap'
    },
    noPadding: {
        padding: 0
    }
}));
