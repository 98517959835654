import * as React from "react";
import {createElement, useEffect, useState} from "react";
import {gs} from "../../theme";
import {Box, 
    Breadcrumbs, 
    Grid, 
    LinearProgress, 
    Paper, 
    TableContainer, 
    Toolbar, 
    Typography, 
    Dialog, 
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, 
    Button,
    TextField} from "@material-ui/core";
import {Crumb} from "../Crumb";
import {ContainerLayout} from "../ContainerLayout";
import {Page, Pageable, Category, CategoryCreateRequest} from "../../domain/types";
import {CategoriesProvider} from "../../domain/CategoriesProvider";
import {paging} from "../../domain/paging";
import {ErrorPopup} from "../ErrorPopup";
import {userStore} from "../../domain/UserStore";
import {ApiError} from "../../Api";
import {Loading} from "../Loading";
import {AddFab} from "../AddFab";
import {TableColumnFilter} from "../base/TableColumnFilter";
import {TablePagePagination} from "../base/TablePagePagination";
import {availableColumns, defaultColumns, CategoriesTable} from "./CategoriesTable";
import {RouteComponentProps} from "react-router";
import {messages} from "../../i18n";

interface CategoriesListProps extends RouteComponentProps {
}

export function CategoriesList(props: CategoriesListProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const [pageable, setPageable] = useState<Pageable>({ ...paging.defaultPageable(), sort: 'name', direction: 'asc' });
    const [categories, setCategories] = useState<Page<Category>>();
    const [error, setError] = useState<ApiError|undefined>(undefined);
    const [selectedColumns, setSelectedColumns] = useState<string[]>(defaultColumns);
    const [createMode, setCreateMode] = useState<boolean>(false);
    const [createForm, setCreateForm] = useState<CategoryCreateRequest>({
        id:'',
        name: '',
        image:''
    });
    const [idDefined, setIdDefined] = useState<boolean>(false);

    const load = (p: Pageable) => {
        setLoading(true);
        CategoriesProvider.list(userStore.getTenantId(), p)
            .then((page) => {
                setCategories(page);
                setLoading(false);
            })
            .catch(toggleError);
    };

    useEffect(() => {
        load(pageable);
    }, []);

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    const handleSelectedColumns = (columns: string[]) => {
        setSelectedColumns(columns);
    }

    const handleSortTableChange = (sort: string, direction: 'asc' | 'desc') => {
        const p = {
            ...pageable,
            sort,
            direction
        };
        setPageable(p);
        load(p);
    }

    const toggleCreateMode = () => {
        setCreateMode(!createMode);
    };



    const onInputChange = (event: any) => {
        const {name, value} = event.target;
        
        if(name ==  'name' && !idDefined){
            setCreateForm({
                ...createForm,
                name: value,
                id: value.toLowerCase().replaceAll(' ', '_')
            });
        }else {
            setCreateForm({
                    ...createForm,
                    [name]: value
                });
        }
        
        if(name == 'id'){
            setIdDefined(true);
        }
    };

    const create = () => {
        CategoriesProvider.create(userStore.getTenantId(), createForm)
            .then(c => props.history.push(`/categories/${c.id}`))
            .catch(toggleError);
    };



    return (
        <ContainerLayout>
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <Breadcrumbs>
                        <Crumb label={messages.categories.plural} />
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item style={{flexGrow: 1}}>
                            <Typography variant="h4" gutterBottom>{messages.categories.plural}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        {categories == undefined && (
                            <Box p={3}>
                                <Box p={1} />
                                <Loading />
                            </Box>
                        )}
                        {categories != undefined &&
                        <React.Fragment>
                            <Toolbar>
                                <Grid container spacing={gs}>
                                    <Grid item>
                                        <TableColumnFilter availableColumns={availableColumns} selectedColumns={selectedColumns}
                                                           onChange={(columns) => handleSelectedColumns(columns)} />
                                    </Grid>
                                </Grid>
                            </Toolbar>
                            <TableContainer>
                                <CategoriesTable categories={categories} columns={selectedColumns as any}
                                                 onOpen={d => props.history.push('/categories/' + d.id)}
                                                 onSortChange={handleSortTableChange}
                                />
                            </TableContainer>
                            <TablePagePagination pageable={pageable} page={categories} onPagingChanged={p => load(p)} />
                            {loading &&
                            <LinearProgress variant="indeterminate" />
                            }
                        </React.Fragment>
                        }
                    </Paper>
                </Grid>
            </Grid>

            <AddFab onClick={toggleCreateMode} />
            <Dialog open={createMode} fullWidth maxWidth="sm">
                <DialogTitle>{messages.categories.actions.add}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField fullWidth variant="outlined"
                                       name="name"
                                       value={createForm.name}
                                       onChange={onInputChange}
                                       label={messages.categories.name}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth variant="outlined"
                                       name="id"
                                       value={createForm.id}
                                       onChange={onInputChange}
                                       label={messages.categories.id}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth variant="outlined"
                                       name="image"
                                       value={createForm.image}
                                       onChange={onInputChange}
                                       label={messages.categories.image}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleCreateMode}>{messages.cancel}</Button>
                    <Button variant="contained" color="primary" onClick={create}>{messages.create}</Button>
                </DialogActions>
            </Dialog>
            { error && (
                <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </ContainerLayout>
    );
}
