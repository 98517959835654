import * as React from "react";
import {createElement, useEffect, useMemo, useState} from "react";
import {Cube} from "../../domain/types";
import {CubesProvider} from "../../domain/CubesProvider";
import {userStore} from "../../domain/UserStore";

interface CubeDisplayProps {
    cubeId: string;
    displayName?: string;
    boxNumber?: string;
}

export function CubeDisplay(props: CubeDisplayProps) {
    const {cubeId, displayName, boxNumber} = props;
    const [cube, setCube] = useState<Cube>();

    useEffect(() => {
        CubesProvider.get(userStore.getTenantId(), cubeId).then(setCube);
    }, [cubeId]);

    const label = useMemo<string>(() => {
        if(displayName) {
            return displayName;
        }
        if(cube) {
            return cube.description || cube.cubeId;
        }
        return cubeId;
    }, [cubeId, displayName, cube]);    

    if(boxNumber) {
        return (
            <span title={cubeId}>{`${label} / ${boxNumber}`}</span>
        );
    }

    return (
        <span title={cubeId}>{label}</span>
    );
}
