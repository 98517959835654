import {createElement, useEffect, useState} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {CssBaseline, Grid, MuiThemeProvider} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import customTheme from "./theme";
import {
    AuthCallbackProcessingPath,
    AuthCallbackRoute,
    AuthLogin,
    AuthLoginPath,
    AuthLogout,
    AuthLogoutPath, authStorage
} from "./Auth";
import {NotFound} from "./components/NotFound";
import FooterBar from "./components/FooterBar";
import {NavBar} from "./components/NavBar";
import {BoxDetails, CubeDetails, CubesList} from "./components/cubes";
import {userStore} from "./domain/UserStore";
import {UserDetails, UsersList} from "./components/users";
import {TenantSelector} from "./components/TenantSelector";
import {SelectAvailableProduct} from "./components/vendition/SelectAvailableProduct";
import {CategoriesList} from "./components/categories/CategoriesList";
import {CategoryView} from "./components/categories/CategoryView";
import {ProductsList} from "./components/products/ProductsList";
import {ProductView} from "./components/products/ProductView";
import {InvoicesList} from "./components/invoices/InvoicesList";
import {InvoiceView} from "./components/invoices/InvoiceView";
import {VendProduct} from "./components/vendition/VendProduct";
import { CategoryEdit } from './components/categories/CategoryEdit';
import { ProductEdit } from './components/products/ProductEdit';
import {SettingView} from "./components/SettingView";
import {UserEdit} from "./components/users/edit";

export const App = () => {
    const [hasTenant, setHasTenant] = useState<boolean>(false);

    useEffect(() => {
        if (authStorage.authenticated && userStore.tenant) {
            setHasTenant(true);
            userStore.load()
                .then();
        }
    }, [setHasTenant]);

    const handleTenantSelected = () => {
        setHasTenant(true);
        userStore.load()
            .then();
    };

    return (
        <MuiThemeProvider theme={customTheme}>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
                <CssBaseline />
                <BrowserRouter>
                    <Grid container direction="column">
                        <Grid item>
                            {hasTenant && (
                                <NavBar />
                            )}
                        </Grid>
                        <Grid item style={{flexGrow: 1}}>
                            <Switch>
                                <Redirect exact from="/" to={authStorage.authenticated ? '/tenants' : AuthLoginPath} />

                                <Route exact path={AuthLoginPath} component={AuthLogin} />
                                <Route exact path={AuthLogoutPath} component={AuthLogout} />
                                <Route exact path={AuthCallbackProcessingPath} component={AuthCallbackRoute} />

                                <Route exact path="/tenants" render={(props) => <TenantSelector onTenantSelected={handleTenantSelected} {...props}/>} />

                                <Route exact path="/categories/:id" component={CategoryView} />
                                <Route exact path="/categories/:id/edit" component={CategoryEdit} />
                                <Route exact path="/categories" component={CategoriesList} />

                                <Route exact path="/products/:id" component={ProductView} />
                                <Route exact path="/products/:id/edit" component={ProductEdit} />
                                <Route exact path="/products" component={ProductsList} />

                                <Route exact path="/vendition/:inventoryId" component={VendProduct} />
                                <Route exact path="/vendition" component={SelectAvailableProduct} />

                                <Route exact path="/cubes" component={CubesList} />
                                <Route exact path="/cubes/:cubeId" component={CubeDetails} />
                                <Route exact path="/cubes/:cubeId/boxes/:boxNumber" component={BoxDetails} />

                                <Route exact path="/users" component={UsersList} />
                                <Route exact path="/users/:userId" component={UserDetails} />
                                <Route exact path="/users/:userId/edit" component={UserEdit} />

                                <Route exact path="/invoices/:id" component={InvoiceView} />
                                <Route exact path="/invoices" component={InvoicesList} />

                                <Route exact path="/settings" component={SettingView} />

                                <Route component={NotFound} />
                            </Switch>
                        </Grid>
                        <Grid item style={{display: 'flex', flexDirection: 'column-reverse'}}>
                            {hasTenant && (
                                <FooterBar />
                            )}
                        </Grid>
                    </Grid>
                </BrowserRouter>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    );
};
