import * as React from "react";
import {createElement, PropsWithChildren} from "react";
import {Box, Container} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

interface ContainerLayoutProps extends PropsWithChildren<any>{ }

export function ContainerLayout(props: ContainerLayoutProps) {
    const classes = useStyles();
    return (
        <Container className={classes.noPrintPadding}>
            <Box marginTop={5}>{props.children}</Box>
        </Container>
    );
}

const useStyles = makeStyles((theme) => ({
    noPrintPadding: {
        '@media print' : {
            padding: 0
        }
    }
}));
