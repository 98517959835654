import {Inventory, InventoryState, Page, Pageable, ProductInventory} from "./types";
import {Api} from "../Api";
import {paging} from "./paging";

export class VenditionProvider {

    static cubeInventory(tenantId: string, cubeId: string, pageable: Pageable): Promise<Page<ProductInventory>> {
        let path = `tenants/${tenantId}/cube-inventory/${cubeId}`;
        path = paging.attachToUrl(pageable, path);
        return Api.GET<Page<ProductInventory>>(path);
    }

    static inventory(tenantId: string, productId: string, inventoryState: InventoryState): Promise<Inventory[]> {
        let path = `tenants/${tenantId}/product-inventory/${productId}?inventoryState=${inventoryState}`;
        return Api.GET<Inventory[]>(path);
    }

    static vendition(tenantId: string, inventoryId: string, cancel?: boolean): Promise<Inventory> {
        const path = `tenants/${tenantId}/vendition/${inventoryId}`;
        const request = {
            inventoryState: cancel ? InventoryState.Stored : InventoryState.Reserved
        };
        return Api.PUT<Inventory>(path, request);
    }

}
