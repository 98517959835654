import * as React from "react";
import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from "@material-ui/core";
import {createElement, useState} from "react";
import {Page, Invoice} from "../../domain/types";
import {messages} from "../../i18n";
import {EmptyTable} from "../base/EmptyTable";
import {DateComponent, Display} from "../DateComponent";
import notFound from "../../assets/undraw_not_found_60pq.svg";

type Column = 'id' | 'createdAt' | 'customerEmail';

export const availableColumns = ['id', 'createdAt', 'customerEmail'];
export const defaultColumns = ['id', 'createdAt', 'customerEmail'];

type sortKey = 'id' | 'createdAt' | 'customerEmail';

interface InvoiceTableProps {
    invoices: Page<Invoice>;
    columns?: Column[];
    onOpen: (invoice: Invoice) => void;
    selectedInvoices?: Invoice[];
    onSelectionChanged?: (selectedCategories: Invoice[]) => void;
    onSortChange: (sort: string, direction: 'asc' | 'desc') => void;
}

export function InvoicesTable(props: InvoiceTableProps) {
    const {invoices, onOpen, selectedInvoices, onSelectionChanged} = props;
    const columns = props.columns || defaultColumns;
    const allowSelection = onSelectionChanged != undefined && selectedInvoices != undefined;


    const invoiceSelected = (invoice: Invoice) => {
        if (selectedInvoices) {
            return selectedInvoices
                .map(i => i.id)
                .indexOf(invoice.id) != -1;
        }
        return false;
    }

    const selectInvoice = (invoice: Invoice, checked: boolean) => {
        if (selectedInvoices && onSelectionChanged) {
            if (checked) {
                onSelectionChanged([ ...selectedInvoices, invoice]);
            } else {
                onSelectionChanged(selectedInvoices.filter(i => i.id != invoice.id));
            }
        }
    }

    const [orderBy, setOrderBy] = useState<sortKey>('createdAt');
    const [orderAsc, setOrderAsc] = useState<boolean>(false);
    const onSort = (key: sortKey) => {
        const {onSortChange} = props;
        if (orderBy === key) {
            const isAsc = !orderAsc;
            setOrderAsc(isAsc);
            if (onSortChange) {
                onSortChange(key, (isAsc) ? 'asc' : 'desc');
            }
        } else {
            setOrderBy(key);
            if (onSortChange) {
                onSortChange(key, orderAsc ? 'asc' : 'desc');
            }
        }
    }

    const colspan = 3 + columns.length

    return (
        <Table size="small" stickyHeader>
            <TableHead>
                <TableRow>
                    {allowSelection && (
                        <TableCell padding="checkbox">
                            TABLE SELECT ALL
                        </TableCell>
                    )}
                    <TableCell>
                        <TableSortLabel active={orderBy === 'id'}
                                        direction={orderAsc ? 'asc' : 'desc'}
                                        onClick={() => onSort('id')}>
                            {messages.invoices.id}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={orderBy === 'createdAt'}
                                        direction={orderAsc ? 'asc' : 'desc'}
                                        onClick={() => onSort('createdAt')}>
                            {messages.invoices.createdAt}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={orderBy === 'customerEmail'}
                                        direction={orderAsc ? 'asc' : 'desc'}
                                        onClick={() => onSort('customerEmail')}>
                            {messages.invoices.customerEmail}
                        </TableSortLabel>
                    </TableCell>
                    
                </TableRow>
            </TableHead>
            <TableBody>
                {invoices.content.map(i => (
                    <TableRow key={i.id} hover onClick={() => onOpen(i)}>
                        {allowSelection && (
                            <TableCell padding="checkbox" onClick={e => {e.stopPropagation(); e.preventDefault()}}>
                                <Checkbox checked={invoiceSelected(i)} onChange={e => selectInvoice(i, e.target.checked)}/>
                            </TableCell>
                        )}
                        <TableCell>{i.id}</TableCell>
                        <TableCell><DateComponent date={i.createdAt} display={Display.Timestamp}/></TableCell>
                        <TableCell>{i.customerEmail}</TableCell>
                    </TableRow>
                ))}
                {invoices.totalElements == 0 && (
                    <EmptyTable title={messages.invoices.none.title} info={messages.invoices.none.info}
                                src={notFound} colSpan={colspan}
                    />
                )}
            </TableBody>
        </Table>
        );
}
