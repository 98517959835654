import * as React from "react";
import {createElement, useCallback, useEffect, useMemo, useState} from "react";
import {gs} from "../../theme";
import {Box, Breadcrumbs, Button, Grid, LinearProgress, Paper, Typography} from "@material-ui/core";
import {Crumb} from "../Crumb";
import {ContainerLayout} from "../ContainerLayout";
import {Inventory, InventoryState, Product} from "../../domain/types";
import {RouteComponentProps, useHistory} from "react-router";
import {InventoryProvider} from "../../domain/InventoryProvider";
import {userStore} from "../../domain/UserStore";
import {Alert, Color} from "@material-ui/lab";
import {messages} from "../../i18n";
import {VenditionProvider} from "../../domain/VenditionProvider";
import {ProductSummary} from "../products/ProductSummary";
import {LabeledData} from "../LabeledData";
import {DateComponent} from "../DateComponent";
import {PickupSlip} from "./PickupSlip";
import {CubeDisplay} from "../cubes/CubeDisplay";

interface VendProductProps extends RouteComponentProps<{inventoryId: string}>{
}

export function VendProduct(props: VendProductProps) {
    const [inventory, setInventory] = useState<Inventory>();

    useEffect(() => {
        InventoryProvider.get(userStore.getTenantId(), props.match.params.inventoryId).then(setInventory);
    }, []);

    const product = useMemo<Product | undefined>(() => {
        return inventory ? inventory.product : undefined;
    }, [inventory]);

    return (
        <ContainerLayout>
            <Box display="block" displayPrint="none">
                <Grid container spacing={gs}>
                    <Grid item xs={12}>
                        <Breadcrumbs>
                            <Crumb label={'Verfügbare Produkte'} route="/vendition" />
                            <Crumb label={'Produkt ausgeben'} />
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item style={{flexGrow: 1}}>
                                <Typography variant="h4" gutterBottom>{`Produkt ausgeben: ${product ? product.name : '...'}`}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {inventory &&
                    <Grid item xs={12}>
                        <InventoryStateInfo inventory={inventory} />
                    </Grid>
                    }
                    <Grid item xs={12}>
                        <Grid container spacing={gs}>
                            <Grid item xs={12} md={4} sm={6}>
                                {(inventory && inventory.state == InventoryState.Stored) &&
                                <CreateReservation inventory={inventory} onInventoryChanged={setInventory} />
                                }
                                {(inventory && inventory.state == InventoryState.Reserved) &&
                                <CancelReservation inventory={inventory} onInventoryChanged={setInventory} />
                                }
                                {(inventory && (inventory.state != InventoryState.Stored && inventory.state != InventoryState.Reserved)) &&
                                <Paper>
                                    <Box p={2}>
                                        <StorageDisplay inventory={inventory} />
                                    </Box>
                                </Paper>
                                }
                            </Grid>
                            {product &&
                            <Grid item xs={12} md={8} sm={6}>
                                <ProductSummary product={product} />
                            </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box display="none" displayPrint="block">
                {inventory && <PickupSlip inventory={inventory} />}
            </Box>
        </ContainerLayout>
    );
}

interface InventoryStateInfoProps {
    inventory: Inventory;
}

function InventoryStateInfo(props: InventoryStateInfoProps) {
    const history = useHistory();
    const {inventory} = props;
    if(inventory.state == InventoryState.Stored) {
        return null;
    }
    let severity: Color = 'warning';
    let message = null;
    switch (inventory.state) {
        case InventoryState.Sold:
            severity = 'success';
            message = 'Dieser Warenbestand wurde bereits verkauft, bitte wählen Sie einen anderen Warenbestand zu diesem Produkt.';
            break;
        case InventoryState.Reserved:
            message = 'Dieser Warenbestand ist bereits reserviert, bitte wählen Sie einen anderen Warenbestand zu diesem Produkt.';
            break;
        case InventoryState.Discarded:
            message = 'Dieser Warenbestand wurde ausgelagert, bitte wählen Sie einen anderen Warenbestand zu diesem Produkt.';
            break;
    }
    return (
        <Paper>
            <Alert severity={severity} action={<Button size="small" onClick={() => history.push(`/products/${inventory.product.id}`)}>Warenbestand suchen</Button>}>{message}</Alert>
        </Paper>
    );
}

interface CreateReservationProps {
    inventory: Inventory;
    onInventoryChanged: (inventory: Inventory) => void;
}

function CreateReservation(props: CreateReservationProps) {
    const history = useHistory();
    const {inventory, onInventoryChanged} = props;

    const [loading, setLoading] = useState<boolean>(false);

    const handleCreateReservation = useCallback(() => {
        setLoading(true);
        VenditionProvider.vendition(userStore.getTenantId(), inventory.id)
            .then((inventory) => {
                onInventoryChanged(inventory);
            })
            .finally(() => setLoading(false));
    }, [inventory]);

    return (
        <Paper>
            <Box p={2}>
                <Grid container spacing={gs}>
                    <Grid item xs={12}>
                        <Grid container spacing={gs}>
                            <Grid item style={{flexGrow: 1}}>
                                <Button variant="contained" color="primary" onClick={handleCreateReservation}>Ausgabeschein erzeugen</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" onClick={() => history.push('/vendition')}>{messages.cancel}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <StorageDisplay inventory={inventory} />
                    </Grid>
                </Grid>
            </Box>
            {loading &&
            <LinearProgress variant="indeterminate" />
            }
        </Paper>
    );
}

interface CancelReservationProps {
    inventory: Inventory;
    onInventoryChanged: (inventory: Inventory) => void;
}

function CancelReservation(props: CancelReservationProps) {
    const history = useHistory();
    const {inventory, onInventoryChanged} = props;

    const [loading, setLoading] = useState<boolean>(false);

    const handleCancelReservation = useCallback(() => {
        setLoading(true);
        VenditionProvider.vendition(userStore.getTenantId(), inventory.id, true)
            .then((inventory) => {
                onInventoryChanged(inventory);
            })
            .finally(() => setLoading(false));
    }, [inventory]);

    return (
        <Paper>
            <Box p={2}>
                <Grid container spacing={gs}>
                    <Grid item xs={12}>
                        <Grid container spacing={gs}>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={() => window.print()}>Ausgabeschein drucken</Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <StorageDisplay inventory={inventory} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={gs}>
                                <Grid item style={{flexGrow: 1}}>
                                    <Button variant="contained" color="default" onClick={handleCancelReservation}>Storno</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={() => history.push('/vendition')}>{messages.cancel}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {loading &&
            <LinearProgress variant="indeterminate" />
            }
        </Paper>
    );
}

interface StorageDisplayProps {
    inventory: Inventory;
}

function StorageDisplay(props: StorageDisplayProps) {
    const {inventory} = props;
    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <LabeledData label="Lagerort">
                    <CubeDisplay cubeId={inventory.cubeId} boxNumber={inventory.boxNumber} />
                </LabeledData>
            </Grid>
            <Grid item xs={6}>
                <LabeledData label="Eingelagert seit">
                    <DateComponent date={inventory.createdAt} />
                </LabeledData>
            </Grid>
            {inventory.soldAt &&
            <Grid item xs={6}>
                <LabeledData label="Ausgegeben am">
                    <DateComponent date={inventory.soldAt} />
                </LabeledData>
            </Grid>
            }
            {inventory.discardedAt &&
            <Grid item xs={6}>
                <LabeledData label="Ausgelagert am">
                    <DateComponent date={inventory.discardedAt} />
                </LabeledData>
            </Grid>
            }
            {inventory.state == InventoryState.Reserved &&
            <Grid item xs={12}>
                <LabeledData label="Abholcode">
                    <Typography variant="h5">{inventory.accessCode}</Typography>
                </LabeledData>
            </Grid>
            }
        </Grid>
    );
}
