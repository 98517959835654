import * as React from "react";
import {Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Typography, Box} from "@material-ui/core";
import {createElement, useEffect, useState} from "react";
import {Inventory} from "../../domain/types";
import {messages} from "../../i18n";


interface InvoiceInventoryTableProps {
    inventories: Inventory[];
}

interface Tax {
	[taxRate: string]: number;
}

export function InvoiceInventoryTable(props: InvoiceInventoryTableProps) {
    const {inventories} = props;
	const[sumNet, setSumNet] = useState<number>(0);
	const[sumGross, setSumGross] = useState<number>(0);
	const[sumTax, setSumTax] = useState<Tax>({});
	

	useEffect(() => {
		let net = 0;
		let gross = 0;
		let tax:Tax ={};
        inventories.map((i) => {
			net += i.amount.net;
			gross += i.amount.gross;
			tax[i.amount.taxRate] = tax[i.amount.taxRate]?tax[i.amount.taxRate]+i.amount.tax:i.amount.tax;
		});
		setSumTax(tax);
		setSumNet(net);
		setSumGross(gross);
    }, []);
    

    return (
        <Table size="small" stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <TableSortLabel active={false} hideSortIcon={true}>
                            {messages.products.foreignId}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={false} hideSortIcon={true}>
                            {messages.products.name}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={false} hideSortIcon={true}>
                            {messages.amount.taxRate}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={false} hideSortIcon={true}>
                            {messages.amount.net}
                        </TableSortLabel>
                    </TableCell>
                    
                    
                </TableRow>
            </TableHead>
            <TableBody>
                {inventories.map(i => (
                    <TableRow key={i.id}>
                        <TableCell>{i.product.foreignId}</TableCell>
                        <TableCell>{i.product.name}</TableCell>
                        <TableCell>{i.amount.taxRate} %</TableCell>
                        <TableCell align="right">{new Intl.NumberFormat("de-AT", {style: 'currency',currency:'EUR'}).format(i.amount.net)}</TableCell>
                    </TableRow>
                ))}
                <TableRow>
                	<TableCell colSpan={3} align="right" style={{borderBottom:"none"}}>
                		{messages.invoices.sumNet}
                	</TableCell>
                	<TableCell  style={{borderBottom:"none"}} align="right">
                		{new Intl.NumberFormat("de-AT", {style: 'currency',currency:'EUR'}).format(sumNet)}
                	</TableCell>
                </TableRow>

				{
				Object.keys(sumTax).map((key, index) => ( 
	                <TableRow key={key}>
	                	<TableCell colSpan={3} align="right"  style={{borderBottom:"none"}}>
	                		{messages.invoices.sumTax} {key}%
	                	</TableCell>
	                	<TableCell style={{borderBottom:"none"}} align="right">
	                		{new Intl.NumberFormat("de-AT", {style: 'currency',currency:'EUR'}).format(sumTax[key])}
	                	</TableCell>
	                </TableRow>
				))
				}

                <TableRow>
                	<TableCell colSpan={3} align="right">
                		<Box fontWeight="fontWeightBold">{messages.invoices.sumGross}</Box>
                	</TableCell>
                	<TableCell align="right">
                		<Box fontWeight="fontWeightBold">{new Intl.NumberFormat("de-AT", {style: 'currency',currency:'EUR'}).format(sumGross)}</Box>
                	</TableCell>
                </TableRow>
            </TableBody>
        </Table>
        );
}
