import * as React from "react";
import {createElement, useEffect, useState} from "react";
import {Inventory, InventoryState, Product} from "../../domain/types";
import {Box, Grid, Paper} from "@material-ui/core";
import {gs} from "../../theme";
import {LabeledData} from "../LabeledData";
import {AvailabilityDisplay} from "../base/AvailabilityDisplay";
import {ProductsProvider} from "../../domain/ProductsProvider";
import {userStore} from "../../domain/UserStore";
import {VenditionProvider} from "../../domain/VenditionProvider";
import {ImageWithPopover} from "../ImageWithPopover";
import { messages } from '../../i18n';

interface ProductSummaryProps {
    product: Product;
}

export function ProductSummary(props: ProductSummaryProps) {
    const {product} = props;

    const [inventory, setInventory] = useState<Inventory[]>();

    useEffect(() => {
        VenditionProvider.inventory(userStore.getTenantId(), product.id, InventoryState.Stored).then(setInventory);
    }, []);

    return (
        <Paper>
            <Box p={2}>
                <Grid container spacing={gs}>
                    <Grid item md={8} sm={6} xs={12}>
                        <Grid container spacing={gs}>
                            <Grid item>
                                <LabeledData label={messages.products.foreignId}>{product.foreignId}</LabeledData>
                            </Grid>
                            <Grid item>
                                <LabeledData label={messages.products.name}>{product.name}</LabeledData>
                            </Grid>
                            <Grid item>
                                <LabeledData label={messages.amount.net}>{new Intl.NumberFormat("de-AT", {style: 'currency',currency:'EUR'}).format(product.price.net)}</LabeledData>
                            </Grid>
                            <Grid item>
                                <LabeledData label={messages.amount.taxRate}>{product.price.taxRate}%</LabeledData>
                            </Grid>
                            <Grid item>
                                <LabeledData label={messages.amount.tax}>{new Intl.NumberFormat("de-AT", {style: 'currency',currency:'EUR'}).format(product.price.tax)}</LabeledData>
                            </Grid>
                            <Grid item style={{flexGrow: 1}}>
                                <LabeledData label={messages.amount.gross}>{new Intl.NumberFormat("de-AT", {style: 'currency',currency:'EUR'}).format(product.price.gross)}</LabeledData>
                            </Grid>
                            <Grid item>
                                {inventory &&
                                <AvailabilityDisplay availability={inventory.length} inventoryLimit={product.inventoryLimit || 3} />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <ImageWithPopover src={product.image} />
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
}
