import * as React from "react";
import {createElement, PropsWithChildren} from "react";
import {Box, Container} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

interface MonospaceProps extends PropsWithChildren<any>{ }

export function Monospace(props: MonospaceProps) {
    const classes = useStyles();
    return (
        <Container className={classes.monospaceFont}>
            {props.children}
        </Container>
    );
}

const useStyles = makeStyles((theme) => ({
    monospaceFont: {
        'font-family': `'courier new', courier, monospace`,
        'whiteSpace': 'pre',
        'fontSize': '.9em' 
    }
}));
