import {Page, Pageable, Invoice} from "./types";
import {Api} from "../Api";
import {paging} from "./paging";

export class InvoicesProvider {

    static get(tenantId: string, invoiceId: string): Promise<Invoice> {
        return Api.GET<Invoice>(`tenants/${tenantId}/invoices/${invoiceId}`);
    }

    static list(tenantId: string, pageable: Pageable): Promise<Page<Invoice>> {
        const path = paging.attachToUrl(pageable, `tenants/${tenantId}/invoices`);
        return Api.GET<Page<Invoice>>(path);
    }
    
    static resend(tenantId: string, invoiceId:string): Promise<Boolean> {
		return Api.GET<Boolean>(`tenants/${tenantId}/invoices/${invoiceId}/resend`);
	}

}
