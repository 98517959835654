import {Page, Pageable, Product, ProductCreateRequest} from "./types";
import {Api} from "../Api";
import {paging} from "./paging";

export class ProductsProvider {

    static get(tenantId: string, productId: string): Promise<Product> {
        return Api.GET<Product>(`tenants/${tenantId}/products/${productId}`);
    }

    static list(tenantId: string, pageable: Pageable): Promise<Page<Product>> {
        const path = paging.attachToUrl(pageable, `tenants/${tenantId}/products`);
        return Api.GET<Page<Product>>(path);
    }

    static create(tenantId: string, request: ProductCreateRequest) {
        return Api.POST<Product>(`tenants/${tenantId}/products`, request);
    }

    static save(tenantId: string, request: Product) {
        return Api.PUT<Product>(`tenants/${tenantId}/products`, request);
    }

    static delete(tenantId: string, id: string) {
        return Api.DELETE<Product>(`tenants/${tenantId}/products/${id}`);
    }
}
