import {DateTime} from "luxon";

export interface Page<T> {
    content: T[];
    totalPages: number;
    totalElements: number;
    number: number;
    numberOfElements: number;
    size: number;
    last: boolean;
    first: boolean;
    hasContent: boolean;
    hasNext: boolean;
    hasPrevious: boolean;
}

export interface Pageable {
    pageNumber: number;
    pageSize: number;
    sort?: string;
    direction?: 'asc' | 'desc';
    unpaged?: boolean;
}

export enum DeliveryService {
    Outbound = "Outbound",
    Inbound = "Inbound"
}

export enum DeliveryState {
    Created = "Created",
    Stored = "Stored",
    PickedUp = "PickedUp",
    Retrieved = "Retrieved",
    Canceled = "Canceled",
}

export interface Delivery {
    id: string;
    carrierId: string;
    deliveryId: string;
    service: DeliveryService;
    state: DeliveryState;
    cubeId: string;
    boxNumber: string;
    reservedBoxNumber: string;
    carrierDeliveryCode?: string;
    carrierRetrievalCode: string;
    recipientPickupCode?: string;

    signature?: string;

    created: DateTime;
    ended: DateTime;

    reservationStart?: DateTime;
    reservationEnd?: DateTime;

    stored?: DateTime;
    pickedUp?: DateTime;
    retrieved?: DateTime;
    cancelled?: DateTime;

    notifiedMaxStorageDays?: DateTime;
    notifiedCriticalStorageDays?: DateTime;
    disabledCarrierNotification?: boolean;

    notes: string;
}

export interface DeliveryCreateRequest {
    carrierId: string;
    cubeId: string;
    boxType: string;
    parcelNumber: string;
    disableCarrierNotification: boolean;
}

export interface DeliveryFilter {
    fulltext?: string;
    states: DeliveryState[];
    carriers: string[];
    services: DeliveryService[];
    cubes: string[];
    from?: DateTime;
    until?: DateTime;
}

export interface Utilization {
    total: number;
    occupied: number;
    available: number;
    disabled: number;
}

export enum AppLockStatus {
    Open = "Open",
    Closed = "Closed",
    Breakin = "Breakin",
    Blocked = "Blocked",
}

export interface Occupancy {
    uuid: string;
    cubeId: string;
    boxNumber: string;
    lock: string;
    accessCode: string;
    accessKeys: string[];
    created: DateTime;
    updated: DateTime;
    ended?: DateTime;
    productId: string;
}

export interface CarrierAccessCode {
    accessCode: string;
    pin: string;
}

export interface AccessCodes {
    carrier: {[carrierId: string]: CarrierAccessCode};
    readOnly: string;
    openNonOccupied: string;
    fullAccess: string;
}

export interface Cube {
    cubeId: string;
    description: string;
    connected: boolean;
    latitude: number;
    longitude: number;
    utilization: Utilization;
    imageUrl: string;
    downTimes: CubeDownTime[];
    accessCodes: AccessCodes;
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    countryCode: string;
}

export interface CubeSetting{
	cubeId: string;
	version: number;
    reminderEmailAdresses: string;
    foreignId: string;
}

export interface CubeDownTime {
    key: string;
    startDate: DateTime;
    endDate: DateTime;
    comment: string;
}

export interface Box {
    number: string;
    description: string;
    lock: string;
    secondaryLock?: string;
    types: string[];
    lockStatus: AppLockStatus;
    secondaryLockStatus?: AppLockStatus;
    disabled: boolean;
    occupancies: Occupancy[];
}

export interface SetBoxStateRequest {
    disabled: boolean;
}

export interface Address {
    street: string;
    houseNo: string;
    zip: string;
    city: string;
    country: string;
}

export interface Tenant {
    id: string;
    name: string;
    address: Address;
    disclaimer: string;
    logo: string;
    accessCode: string;
}

export enum UserRole {
    Admin = 'Admin',
    User = 'User'
}

export interface User {
    uuid: string;
    username: string;
    email: string;
    role: UserRole;
    active: boolean;
    carrierId: string;
    root: boolean;
    apiToken?: string;
    cubeId?: string;
}

export interface UserCreateRequest {
    email: string;
    role: UserRole;
}

export interface UserUpdateRequest {
    cubeId?: string;
}

export enum QueueStatus {
    Created = "Created",
    Failed = "Failed",
    Succeeded = "Succeeded"

}

export interface QueueItem {
    uuid: string;
    version: number;
    deliveryId: string;
    status: QueueStatus;
    state: string;
    actionType: string;
    scannedBarcode: string;
    signature: string;
    code: string;
    name: string;
    email: string;
    phone: string;
    response: string;
    failureCount: number;
    created: DateTime;
    retry: DateTime;
    delivered: DateTime;
    expired: DateTime;
}

export interface CarrierToken {
    uuid: string;
    carrierId: string;
}

export interface CarrierTokenCreateResponse {
    uuid: string;
    carrierId: string;
    rawToken: string;
}

export interface Carrier {
    id: string;
    name?: string;
    dataRetentionPolicyDays?: number;
}

export interface CarrierCreateRequest extends Carrier {
}

export interface CarrierUpdateRequest extends Omit<Carrier, 'id'> {
}

export const KNOWN_CARRIER_IDS = ['dpd'];

export interface Statistics {
    boxTypes: { [key: string]: number; };
    cubes: { [key: string]: number; };
    states: { [key: string]: number; };
    services: { [key: string]: number; };
}

export interface TaxedAmount {
    net: number;
    taxRate: number;
    tax: number;
    gross: number;
}

export interface Category {
    id: string;
    version: number;
    tenantId: string;
    name: string;
    image: string;
}

export interface CategoryCreateRequest {
    id: string;
    name: string;
    image: string;
}

export interface Product {
    id: string;
    foreignId: string;
    version: number;
    name: string;
    image: string;
    description: string;
    category: Category;
    price: TaxedAmount;
    inventoryLimit: number;
}

export interface Invoice {
	id: string;
	version: number;
	createdAt: DateTime;
	customerEmail: string;
	customerReceipt: string;
	merchantReceipt: string;
	lines: Inventory[];

}

export interface ProductCreateRequest {
    id: string;
    foreignId: string;
    name: string;
    image: string;
    price: TaxedAmount;
    category: Category;
}

export interface ProductInventory {
    product: Product;
    inventory: string[];
    availability: number;
}

export enum InventoryState {
    Stored = "Stored",
    Reserved = "Reserved",
    Sold = "Sold",
    Discarded = "Discarded"
}

export interface Inventory {
    id: string;
    version: number;
    state: InventoryState;
    product: Product;
    amount: TaxedAmount;
    cubeId: string;
    boxNumber: string;
    createdAt: DateTime;
    soldAt?: DateTime;
    discardedAt?: DateTime;
    accessCode: string;
    customerEmail: string;
}

export interface ChangePasswordRequest {
    password: string,
    confirmPassword: string
}
