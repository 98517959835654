import {Api} from "../Api";
import {Box, Cube, CubeSetting, CubeDownTime, Page, Pageable, SetBoxStateRequest} from "./types";
import {paging} from "./paging";

export class CubesProvider {

    static list(tenantId: string, pageable?: Pageable) {
        if(pageable) {
            return Api.GET<Page<Cube>>(paging.attachToUrl(pageable, `tenants/${tenantId}/cubes`));
        }else {
            return Api.GET<Page<Cube>>(`tenants/${tenantId}/cubes`);
        }
    }

    static get(tenantId: string, cubeId: string) {
        return Api.GET<Cube>(`tenants/${tenantId}/cubes/${cubeId}`);
    }

    static getSetting(tenantId: string, cubeId: string) {
        return Api.GET<CubeSetting>(`tenants/${tenantId}/cubes/${cubeId}/settings`);
    }

    static setImage(tenantId: string, cubeId: string, imageUrl: string) {
        return Api.PUT<void>(`tenants/${tenantId}/cubes/${cubeId}/image`, { imageUrl });
    }

    static setReminderEmail(tenantId: string, cubeId: string, reminderEmail: string) {
        return Api.PUT<void>(`tenants/${tenantId}/cubes/${cubeId}/reminderEmail`, { reminderEmail });
    }

    static setForeignId(tenantId: string, cubeId: string, foreignId: string) {
        return Api.PUT<void>(`tenants/${tenantId}/cubes/${cubeId}/foreign-id`, { foreignId });
    }

    static setCarrierAccessCode(tenantId: string, cubeId: string, carrierId: string, accessCode: string, pin: string) {
        const request = {
            carrierId: carrierId,
            accessCode: accessCode,
            pin: pin
        };
        return Api.PUT<void>(`tenants/${tenantId}/cubes/${cubeId}/carrier-access-code`, request);
    }

    static setDownTimes(tenantId: string, cubeId: string, downtimes: CubeDownTime[]) {
        downtimes = downtimes
            .map(t => {
                t.startDate = t.startDate.toJSDate().toISOString() as any;
                t.endDate = t.endDate.toJSDate().toISOString() as any;
                return t;
            });
        return Api.PUT<void>(`tenants/${tenantId}/cubes/${cubeId}/downtimes`, downtimes);
    }

    static listBoxes(tenantId: string, cubeId: string) {
        return Api.GET<Box[]>(`tenants/${tenantId}/cubes/${cubeId}/boxes`);
    }

    static getBox(tenantId: string, cubeId: string, boxNumber: string) {
        return Api.GET<Box>(`tenants/${tenantId}/cubes/${cubeId}/boxes/${boxNumber}`);
    }

    static openAllBoxes(tenantId: string, cubeId: string) {
        return Api.POST<void>(`tenants/${tenantId}/cubes/${cubeId}/boxes/open-all-boxes`, {});
    }

    static openBox(tenantId: string, cubeId: string, boxNumber: string) {
        return Api.POST<void>(`tenants/${tenantId}/cubes/${cubeId}/boxes/${boxNumber}/open`, {});
    }

    static setBoxState(tenantId: string, cubeId: string, boxNumber: string, request: SetBoxStateRequest) {
        return Api.PUT<void>(`tenants/${tenantId}/cubes/${cubeId}/boxes/${boxNumber}/set-state`, request);
    }
}
