import * as React from "react";
import {createElement, useCallback, useEffect, useState} from "react";
import {RouteComponentProps, useHistory} from "react-router";
import {ContainerLayout} from "../ContainerLayout";
import {Box, Breadcrumbs, Button, Grid, Paper, Typography, ButtonGroup} from "@material-ui/core";
import {gs} from "../../theme";
import {Crumb} from "../Crumb";
import {Category} from "../../domain/types";
import {CategoriesProvider} from "../../domain/CategoriesProvider";
import {userStore} from "../../domain/UserStore";
import {LabeledData} from "../LabeledData";
import {ImageWithPopover} from "../ImageWithPopover";
import {AvailabilityDisplay} from "../base/AvailabilityDisplay";
import {Loading} from "../Loading";
import {messages} from "../../i18n";
import notFound from "../../assets/undraw_not_found_60pq.svg";
import { EditFab } from "../EditFab";

interface CategoryViewProps extends RouteComponentProps<{id: string}>{
}

export function CategoryView(props: CategoryViewProps) {
    const history = useHistory();
    const [category, setCategory] = useState<Category>();

    useEffect(() => {
        const categoryId = props.match.params.id;
        CategoriesProvider.get(userStore.getTenantId(), categoryId).then(setCategory);
    }, []);

    const edit = () => {
        history.push('/categories/' + category?.id + '/edit');
    }
    

    return (
        <ContainerLayout>
            <Grid container spacing={gs}>
                <Grid item xs={8}>
                    <Breadcrumbs>
                        <Crumb label={messages.categories.plural} route="/categories" />
                        <Crumb label={messages.categories.singular+`: ${category ? category.name : '...'}`} />
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={4}>
                	<Typography align="right">
	                    <ButtonGroup>
	                        <Button onClick={history.goBack}>{messages.back}</Button>
	                        <Button onClick={edit} variant="contained" color="primary">{messages.edit}</Button>
	                    </ButtonGroup>
	                </Typography>
				</Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item style={{flexGrow: 1}}>
                            <Typography variant="h4" gutterBottom>{messages.categories.singular+`: ${category ? category.name : '...'}`}</Typography>
                        </Grid>
                        
                    </Grid>
                </Grid>
                {!category && <Loading />}
                {category &&
                <React.Fragment>
                    <Grid item xs={12} md={8} sm={6}>
                        <Grid container spacing={gs} justify="flex-end">
                            <Grid item xs={12}>
                                <Paper>
                                    <Box p={2}>
                                        <Grid container spacing={gs}>
                                            <Grid item>
                                                <LabeledData label={messages.categories.name}>{category.name}</LabeledData>
                                            </Grid>
                                            <Grid item>
                                                <LabeledData label={messages.categories.id}>{category.id}</LabeledData>
                                            </Grid>
                                            <Grid item>
                                                <LabeledData label={messages.categories.version}><Typography align="right">{category.version}</Typography></LabeledData>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} sm={6}>
                        <Paper>
                            {category.image && <ImageWithPopover src={category.image} />}
                            {!category.image && <img width="100%" alt="{messages.notFound}" src={notFound} />}
                        </Paper>
                    </Grid>
                </React.Fragment>
                }
            </Grid>
            <EditFab onClick={edit}/>
        </ContainerLayout>
    );
}
