import * as React from "react";
import {createElement, useEffect, useState} from "react";
import { Product, ProductCreateRequest, Category, Pageable, Page } from '../../domain/types';
import { ApiError } from '../../Api';
import { messages } from '../../i18n';
import { ProductsProvider } from '../../domain/ProductsProvider';
import { userStore } from '../../domain/UserStore';
import { RouteComponentProps, useHistory } from 'react-router';
import { ErrorPopup } from '../ErrorPopup';
import { ContainerLayout } from '../ContainerLayout';
import { Grid, Paper, Box, Breadcrumbs, Button, Typography, TextField, ButtonGroup } from '@material-ui/core';
import { gs } from '../../theme';
import { Loading } from '../Loading';
import { Crumb } from '../Crumb';
import { ImageWithPopover } from '../ImageWithPopover';
import { CategoriesProvider } from '../../domain/CategoriesProvider';
import { Autocomplete } from '@material-ui/lab';
import { paging } from '../../domain/paging';
import { ConfirmDialog } from '../ConfirmDialog';
import { SaveFab } from '../SaveFab';

interface ProductEditProps extends RouteComponentProps<{id: string}> {
}

interface ErrorSpec {
    title: string;
    message: string;
}

export function ProductEdit(props: ProductEditProps) {
	const [error, setError] = useState<ApiError|undefined>(undefined);
	const history = useHistory();
	const [product, setProduct] = useState<Product>();
	const [categories, setCategories] = useState<Page<Category>>();
	const [unpaged, setUnpaged] = useState<Pageable>({ ...paging.defaultPageable(), sort: 'name', direction: 'asc', unpaged: true });
	const [categoriesLoading, setCategoriesLoading] = useState<boolean>(false);
	const [deleteMode, setDeleteMode] = useState<boolean>(false);
    const [errorSpec, setErrorSpec] = useState<ErrorSpec|undefined>(undefined);
	
	useEffect(() => {
		const productId = props.match.params.id;
        ProductsProvider.get(userStore.getTenantId(), productId).then(setProduct);
        loadCategories(unpaged);
    }, []);

	const toggleError = (error?: ApiError) => {
        if(error?.code == 409){
            setErrorSpec({
                title: messages.products.delete.inventoryExistError.title,
                message: messages.products.delete.inventoryExistError.message
            });
        }else{
            setErrorSpec(undefined);
        }
        setError(error);
	};

	const deleteProduct = () => {
        if(product) ProductsProvider.delete(userStore.getTenantId(), product.id)
            .then(p => props.history.push(`/products`))
            .catch(toggleError);
    }

    const toggleDelete = () => {
        setDeleteMode(!deleteMode);
    };
	
	const save = () => {
		console.log('productid: ' + product?.id)
		if(product) ProductsProvider.save(userStore.getTenantId(), product)
            .then(p => props.history.goBack())
            .catch(toggleError);
	}

	const round = (val:number) => {
        return Math.round(val*100)/100;
    }

    const onInputChange = (event: any) => {
        const {name, value} = event.target;

        let val = parseFloat(value);
        if(isNaN(val)) val = 0;
		
		if(product){
			if(name == 'net'){
				let gross = round(val + (val*product.price.taxRate/100))
				setProduct({
					...product,
					price: {
						net: value,
						taxRate: product.price.taxRate,
						tax: round(gross-val),
						gross: gross
					}
					// price: {} tax: (val*createForm.taxRate/100),
					// gross: (val + (val*createForm.taxRate/100))
				})
			} else if(name == 'taxRate') {
				let gross = round((Number(product.price.net) + (product.price.net*val/100)))
				setProduct({
					...product,
					price: {
						net: product.price.net,
						taxRate: value,
						tax: round(gross-product.price.net),
						gross: gross
                	}
                
					// tax: (createForm.net*val/100),
					// gross: (createForm.net + (createForm.net*val/100))
				})
			} else if(name == 'gross'){
				let net = round(val/(1+product.price.taxRate/100));
				setProduct({
					...product,
					price: {
						net: net,
						taxRate: product.price.taxRate,
						tax: round(val-net),
						gross: value
					}
					// net: (val/(1+createForm.taxRate/100)),
					// tax: (createForm.net*createForm.taxRate/100)
				})
			} else {
				setProduct({
					...product,
					[name]: value
				});
			}
        }
		
    };
	
	const loadCategories = (p: Pageable) =>  {
		setCategoriesLoading(true);
        CategoriesProvider.list(userStore.getTenantId(), p)
            .then((page) => {
                setCategories(page);
                setCategoriesLoading(false);
            })
            .catch(toggleError);
     }
	
	
	
	return (
		<ContainerLayout>
			<Grid container spacing={gs}>
                <Grid item xs={8}>
                    <Breadcrumbs>
                        <Crumb label={messages.products.plural} route="/products" />
                        <Crumb label={`${messages.products.singular}: ${product ? product.name: '...'}`} route={`/products/${product?.id}`}/>
                        <Crumb label={messages.edit} />
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={8}>
                	<Typography align="right">
						<ButtonGroup>
							<Button onClick={history.goBack}>{messages.cancel}</Button>
							<Button onClick={toggleDelete}>{messages.delete}</Button>
							<Button onClick={save} variant="contained" color="primary">{messages.save}</Button>
						</ButtonGroup>
					</Typography>
				</Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item style={{flexGrow: 1}}>
                            <Typography variant="h4" gutterBottom>{`Produkt ` + messages.edit + `: ${product ? product.name : '...'}`}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {!product && <Loading />}
                {product &&
					<React.Fragment>
						<Grid item xs={12} md={8} sm={6}>
							<Grid container spacing={gs} justify="flex-end">
								<Grid item xs={12}>
									<Paper>
										<Box p={2}>
											<Grid container spacing={gs}>
												<Grid item xs={12}>
													<TextField fullWidth variant="outlined"
																name="name"
																value={product.name}
																onChange={onInputChange}
																label={messages.products.name}/>
												</Grid>
												<Grid item xs={8}>
													<TextField fullWidth variant="outlined"
																name="foreignId"
																value={product.foreignId}
																onChange={onInputChange}
																label={messages.products.foreignId}/>
												</Grid>
												<Grid item xs={4}>
													<TextField fullWidth variant="outlined"
																name="inventoryLimit"
																value={product.inventoryLimit}
																onChange={onInputChange}
																label={messages.products.inventoryLimit}/>
												</Grid>
												<Grid item xs={3}>
													<TextField fullWidth variant="outlined"
																name="net"
																value={product.price?.net}
																onChange={onInputChange}
																label={messages.amount.net}/>
												</Grid>
												<Grid item xs={3}>
													<TextField fullWidth variant="outlined"
																name="taxRate"
																value={product.price?.taxRate}
																onChange={onInputChange}
																label={messages.amount.taxRate}/>
													
												</Grid>
												<Grid item xs={3}>
													<TextField fullWidth variant="outlined"
																disabled
																name="tax"
																value={product.price?.tax}
																onChange={onInputChange}
																label={messages.amount.tax}/>
												</Grid>
												<Grid item xs={3}>
													<TextField fullWidth variant="outlined"
																name="gross"
																value={product.price?.gross}
																onChange={onInputChange}
																label={messages.amount.gross}/>
												</Grid>
												<Grid item xs={12}>
													<TextField fullWidth variant="outlined"
																name="image"
																value={product.image}
																onChange={onInputChange}
																label={messages.products.image}/>
												</Grid>
												<Grid item xs={12}>
													<Autocomplete id='category'
																autoSelect
																handleHomeEndKeys
																fullWidth
																openOnFocus
																value={product.category}
																loading={categoriesLoading}
																loadingText={messages.loading}
																options={categories?categories.content:[]}
																getOptionSelected={(option, value) => option.id === value.id}
																onChange={(e, selectedObject: Category|null) => {
																	if (selectedObject !== null){
																		setProduct({
																		...product,
																		category: selectedObject})
																	}
																}}
																getOptionLabel={(option: Category) => (option.name)}
																renderInput={(params) => <TextField {...params} label={messages.products.category} variant="outlined"/>}/>
												</Grid>
												<Grid item xs={12}>
													<TextField fullWidth variant="outlined"
																multiline
																name="description"
																value={product.description}
																onChange={onInputChange}
																label={messages.products.description}/>
												</Grid>
											</Grid>
										</Box>
									</Paper>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={4} sm={6}>
                        <Paper>
                            <ImageWithPopover src={product.image} />
                        </Paper>
                    </Grid>
					</React.Fragment>
				}
			</Grid>
			<SaveFab onClick={save} />
            {deleteMode && (
                <ConfirmDialog title={messages.products.delete.confirm.title} message={messages.products.delete.confirm.message} resolve={deleteProduct} reject={toggleDelete}/>
            )}
			{ error && (
                <ErrorPopup url={error.url} statusCode={error.code} title={errorSpec?.title} errorMessage={errorSpec?.message} onCloseHandler={() => toggleError(undefined)}/>
            )}
		</ContainerLayout>
		
	);
}