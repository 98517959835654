import {Component, createElement} from "react";
import {Fab} from "@material-ui/core";
import {EditIcon} from "../icons";
import customTheme from "../theme";

interface EditFabProps {
    onClick: () => void
}

export class EditFab extends Component<EditFabProps> {

    render() {
        return(
            <Fab color="primary" aria-label="edit"
                 style={{
                     position: 'fixed',
                     bottom: customTheme.spacing(8),
                     right: customTheme.spacing(3)
                 }}
                 onClick={this.props.onClick}>
                <EditIcon />
            </Fab>
        );
    }
}
