import {Api} from "../Api";
import {ChangePasswordRequest, Page, Pageable, User, UserCreateRequest, UserUpdateRequest} from "./types";
import {paging} from "./paging";

export class UsersProvider {

    static list(tenantId: string, pageable: Pageable) {
        return Api.GET<Page<User>>(paging.attachToUrl(pageable,`tenants/${tenantId}/users`));
    }

    static create(tenantId: string, request: UserCreateRequest) {
        return Api.POST<User>(`tenants/${tenantId}/users`, request);
    }

    static me(tenantId: string) {
        return Api.GET<User>(`tenants/${tenantId}/users/current-user`);
    }

    static async changePassword(tenantId: string, request: ChangePasswordRequest) {
        await Api.POST(`tenants/${tenantId}/users/current-user/password`, request);
    }

    static get(tenantId: string, uuid: string) {
        return Api.GET<User>(`tenants/${tenantId}/users/${uuid}`);
    }

    static put(tenantId: string, uuid: string, request: UserUpdateRequest) {
        return Api.PUT<User>(`tenants/${tenantId}/users/${uuid}`, request);
    }
    static delete(tenantId: string, uuid: string) {
        return Api.DELETE<void>(`tenants/${tenantId}/users/${uuid}`);
    }
}
