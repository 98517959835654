import * as React from "react";
import {createElement, useEffect, useState} from "react";
import {RouteComponentProps, useHistory} from "react-router";
import { CategoriesProvider } from '../../domain/CategoriesProvider';
import {userStore} from "../../domain/UserStore";
import { Category, Product } from '../../domain/types';
import { ContainerLayout } from '../ContainerLayout';
import { Grid, Breadcrumbs, Typography, Paper, Box, TextField, Button, ButtonGroup} from '@material-ui/core';
import { Crumb } from '../Crumb';
import { messages } from '../../i18n';
import { gs } from '../../theme';
import { Loading } from '../Loading';
import { ImageWithPopover } from '../ImageWithPopover';
import notFound from "../../assets/undraw_not_found_60pq.svg";
import { ApiError } from '../../Api';
import { ErrorPopup } from '../ErrorPopup';
import { SaveFab } from '../SaveFab';
import { DeleteFab } from '../DeleteFab';
import { ConfirmDialog } from '../ConfirmDialog';

interface CategoryEditProps extends RouteComponentProps<{id: string}>{
}

interface ErrorSpec {
    title: string;
    message: string;
}

export function CategoryEdit(props: CategoryEditProps) {
	const [error, setError] = useState<ApiError|undefined>(undefined);
	const history = useHistory();
    const [category, setCategory] = useState<Category>();
    const [deleteMode, setDeleteMode] = useState<boolean>(false);
    const [errorSpec, setErrorSpec] = useState<ErrorSpec|undefined>(undefined);

    useEffect(() => {
        const categoryId = props.match.params.id;
		CategoriesProvider.get(userStore.getTenantId(), categoryId).then(setCategory).catch(toggleError);
	}, []);

	const toggleError = (error?: ApiError) => {
        if(error?.code == 409){
            setErrorSpec({
                title: messages.categories.delete.productExistError.title,
                message: messages.categories.delete.productExistError.message
            });
        }else{
            setErrorSpec(undefined);
        }
        setError(error);
	};
	
	const onInputChange = (event: any) => {
        const {name, value} = event.target;
        if(category){
            setCategory({
                    ...category,
                    [name]: value
                });
        }
        
	};
	
	const save = () => {
		if(category) CategoriesProvider.save(userStore.getTenantId(), category)
            .then(c => props.history.goBack())
            .catch(toggleError);
    }
    
    const deleteCategory = () => {
        if(category) CategoriesProvider.delete(userStore.getTenantId(), category.id)
            .then(c => props.history.push(`/categories`))
            .catch(toggleError);
    }

    const toggleDelete = () => {
        setDeleteMode(!deleteMode);
    };

	
	return (
        <ContainerLayout>
            <Grid container spacing={gs}>
                <Grid item xs={8}>
                    <Breadcrumbs>
                        <Crumb label={messages.categories.plural} route="/categories" />
                        <Crumb label={`${messages.categories.singular}: ${category ? category.name : '...'}`} route={`/categories/${category?.id}`}/>
                        <Crumb label={messages.edit} />
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={4}>
                	<Typography align="right">
	                    <ButtonGroup>
	                        <Button onClick={history.goBack}>{messages.cancel}</Button>
	                        <Button onClick={toggleDelete}>{messages.delete}</Button>
	                        <Button onClick={save} variant="contained" color="primary">{messages.save}</Button>
	                    </ButtonGroup>
	                </Typography>
				</Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item style={{flexGrow: 1}}>
                            <Typography variant="h4" gutterBottom>{messages.categories.singular+ ' ' + messages.edit +`: ${category ? category.name : '...'}`}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {!category && <Loading />}
                {category &&
                <React.Fragment>
                    <Grid item xs={12} md={8} sm={6}>
                        <Grid container spacing={gs} justify="flex-end">
                            <Grid item xs={12}>
                                <Paper>
                                    <Box p={2}>
                                        <Grid container spacing={gs}>
                                            <Grid item xs={12}>
                                                <TextField fullWidth variant="outlined"
														name="name"
														value={category.name}
														onChange={onInputChange}
														label={messages.categories.name}/>
                                            </Grid>
                                            <Grid item xs={12}>
												<TextField fullWidth variant="outlined"
													disabled
													name="id"
													value={category.id}
													onChange={onInputChange}
													label={messages.categories.id}/>
                                            </Grid>
                                            <Grid item xs={12}>
												<TextField fullWidth variant="outlined"
														name="image"
														value={category.image}
														onChange={onInputChange}
														label={messages.categories.image}/>
											</Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} sm={6}>
                        <Paper>
                            {category.image && <ImageWithPopover src={category.image} />}
                            {!category.image && <img width="100%" src={notFound} />}
                        </Paper>
                    </Grid>
                </React.Fragment>
                }
            </Grid>
            <SaveFab onClick={save} />
            {deleteMode && (
                <ConfirmDialog title={messages.categories.delete.confirm.title} message={messages.categories.delete.confirm.message} resolve={deleteCategory} reject={toggleDelete}/>
            )}
			{ error && (
                <ErrorPopup url={error.url} statusCode={error.code} title={errorSpec?.title} errorMessage={errorSpec?.message} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </ContainerLayout>
    );
}