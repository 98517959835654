import * as React from "react";
import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from "@material-ui/core";
import {createElement, useState} from "react";
import {Page, Product} from "../../domain/types";
import {messages} from "../../i18n";
import {EmptyTable} from "../base/EmptyTable";
import notFound from "../../assets/undraw_not_found_60pq.svg";

type Column = 'foreignId' | 'name' | 'category' | 'net' | 'gross';

export const availableColumns = ['foreignId', 'name', 'category', 'net', 'gross'];
export const defaultColumns = ['foreignId', 'name', 'category', 'net', 'gross'];

type sortKey = 'foreignId' | 'name' | 'category' | 'price.net' | 'price.gross';

interface ProductsTableProps {
    products: Page<Product>;
    columns?: Column[];
    onOpen: (product: Product) => void;
    selectedProducts?: Product[];
    onSelectionChanged?: (selectedProducts: Product[]) => void;
    onSortChange: (sort: string, direction: 'asc' | 'desc') => void;
}

export function ProductsTable(props: ProductsTableProps) {
    const {products, onOpen, selectedProducts, onSelectionChanged} = props;
    const columns = props.columns || defaultColumns;
    const allowSelection = onSelectionChanged != undefined && selectedProducts != undefined;

    const showColumn = (column: Column) => {
        return columns.indexOf(column) != -1;
    }

    const productSelected = (product: Product) => {
        if (selectedProducts) {
            return selectedProducts
                .map(p => p.id)
                .indexOf(product.id) != -1;
        }
        return false;
    }

    const selectProduct = (product: Product, checked: boolean) => {
        if (selectedProducts && onSelectionChanged) {
            if (checked) {
                onSelectionChanged([ ...selectedProducts, product]);
            } else {
                onSelectionChanged(selectedProducts.filter(p => p.id != product.id));
            }
        }
    }

    const [orderBy, setOrderBy] = useState<sortKey>('name');
    const [orderAsc, setOrderAsc] = useState<boolean>(false);
    const onSort = (key: sortKey) => {
        const {onSortChange} = props;
        if (orderBy === key) {
            const isAsc = !orderAsc;
            setOrderAsc(isAsc);
            if (onSortChange) {
                onSortChange(key, (isAsc) ? 'asc' : 'desc');
            }
        } else {
            setOrderBy(key);
            if (onSortChange) {
                onSortChange(key, orderAsc ? 'asc' : 'desc');
            }
        }
    }

    const colspan = 3 + columns.length

    return (
        <Table size="small" stickyHeader>
            <TableHead>
                <TableRow>
                    {allowSelection && (
                        <TableCell padding="checkbox">
                            TABLE SELECT ALL
                        </TableCell>
                    )}
                    <TableCell>
                        <TableSortLabel active={orderBy === 'name'}
                                        direction={orderAsc ? 'asc' : 'desc'}
                                        onClick={() => onSort('name')}>
                            {messages.products.name}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={orderBy === 'foreignId'}
                                        direction={orderAsc ? 'asc' : 'desc'}
                                        onClick={() => onSort('foreignId')}>
                            {messages.products.foreignId}
                        </TableSortLabel>
                    </TableCell>
                    {showColumn('category') && (
                        <TableCell>
                            <TableSortLabel active={orderBy === 'category'}
                                            direction={orderAsc ? 'asc' : 'desc'}
                                            onClick={() => onSort('category')}>
                                {messages.products.category}
                            </TableSortLabel>
                        </TableCell>
                    )}
                    {showColumn('net') && (
                        <TableCell>
                            <TableSortLabel active={orderBy === 'price.net'}
                                            direction={orderAsc ? 'asc' : 'desc'}
                                            onClick={() => onSort('price.net')}>
                                {messages.amount.net}
                            </TableSortLabel>
                        </TableCell>
                    )}
                    {showColumn('gross') && (
                        <TableCell>
                            <TableSortLabel active={orderBy === 'price.gross'}
                                            direction={orderAsc ? 'asc' : 'desc'}
                                            onClick={() => onSort('price.gross')}>
                                {messages.amount.gross}
                            </TableSortLabel>
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {products.content.map(p => (
                    <TableRow key={p.id} hover onClick={() => onOpen(p)}>
                        {allowSelection && (
                            <TableCell padding="checkbox" onClick={e => {e.stopPropagation(); e.preventDefault()}}>
                                <Checkbox checked={productSelected(p)} onChange={e => selectProduct(p, e.target.checked)}/>
                            </TableCell>
                        )}
                        <TableCell>{p.name}</TableCell>
                        <TableCell>{p.foreignId}</TableCell>
                        {showColumn('category') && <TableCell>{p.category.name}</TableCell>}
                        {showColumn('net') && <TableCell>&euro; {p.price?p.price.net.toFixed(2):''}</TableCell>}
                        {showColumn('gross') && <TableCell>&euro; {p.price?p.price.gross.toFixed(2):''}</TableCell>}
                    </TableRow>
                ))}
                {products.totalElements == 0 && (
                    <EmptyTable title={messages.products.none.title} info={messages.products.none.info}
                                src={notFound} colSpan={colspan}
                    />
                )}
            </TableBody>
        </Table>
        );
}
