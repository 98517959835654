import * as React from "react";
import {createElement, useCallback, useEffect, useState} from "react";
import {Product} from "../domain/types";
import {ProductsProvider} from "../domain/ProductsProvider";
import {userStore} from "../domain/UserStore";
import {ApiError} from "../Api";
import {Chip} from "@material-ui/core";
import {messages} from "../i18n";
import {useHistory} from "react-router";

interface InventoryLinkComponentProps {
    productId: string;
    inventoryId?: string;
}

export function ProductLinkComponent(props: InventoryLinkComponentProps) {
    const history = useHistory();
    const {productId, inventoryId} = props;

    const [product, setProduct] = useState<Product>();
    const [error, setError] = useState<ApiError|undefined>(undefined);

    useEffect(() => {
        ProductsProvider.get(userStore.getTenantId(), productId)
            .then(setProduct)
            .catch(toggleError);
    }, [productId]);

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    const handleNavigate = useCallback(() => {
        if(inventoryId) {
            history.push(`/vendition/${inventoryId}`);
        }else {
            history.push(`/products/${productId}`);
        }
    }, [productId, inventoryId]);

    return (
        <React.Fragment>
            {product && (
                <Chip style={{cursor: 'pointer'}} label={product.name} onClick={handleNavigate} />
            )}
            {error && (
                <Chip label={messages.deliveries.notFound(productId)} title={`${error.code}: ${error.message}`} />
            )}
        </React.Fragment>
    );
}
