import {RouteComponentProps} from "react-router";
import {createElement, useEffect, useState, Fragment} from "react";
import {Cube, User} from "../../domain/types";
import {UsersProvider} from "../../domain/UsersProvider";
import {ApiError} from "../../Api";
import {ContainerLayout} from "../ContainerLayout";
import {
    Box,
    Breadcrumbs,
    Button,
    Grid,
    Paper,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@material-ui/core";
import {Loading} from "../Loading";
import {messages} from "../../i18n";
import {Crumb} from "../Crumb";
import {LabeledData} from "../LabeledData";
import {ErrorPopup} from "../ErrorPopup";
import {userStore} from "../../domain/UserStore";
import {Alert} from "@material-ui/lab";
import {CubesProvider} from "../../domain/CubesProvider";

export const UserDetails = (props: RouteComponentProps<{ userId: string }>) => {
    const [user, setUser] = useState<User|undefined>(undefined);
    const [cube, setCube] = useState<Cube>();
    const [error, setError] = useState<ApiError|undefined>(undefined);
    const [deleteModal, setDeleteModal] = useState(false);

    useEffect(() => {
        UsersProvider.get(userStore.getTenantId(), props.match.params.userId)
            .then(u => {
                setUser(u);
                if (u.cubeId) {
                    CubesProvider.get(userStore.getTenantId(), u.cubeId)
                        .then(setCube);
                }

            })
            .catch(toggleError);
    }, [setUser]);

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    const handleDeleteUser = () => {
        UsersProvider.delete(userStore.getTenantId(), props.match.params.userId)
            .then(() => props.history.push('/users'))
            .catch(toggleError);
    }

    return (
        <ContainerLayout>
            {!user && (
                <Box p={5}>
                    <Loading/>
                </Box>
            )}
            {user && (
                <Fragment>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Breadcrumbs>
                                        <Crumb route="/users" label={messages.users.plural} />
                                        <Crumb label={user.email} />
                                    </Breadcrumbs>
                                </Grid>
                                {userStore.admin && (
                                    <Grid item xs={6}>
                                        <Typography align="right">
                                            <Button variant="contained" color="primary" onClick={() => props.history.push('/users/' + user.uuid + '/edit')}>Bearbeiten</Button>
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={2}>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <LabeledData label={messages.users.email}>
                                                {user.email}
                                            </LabeledData>
                                        </Grid>
                                        <Grid item>
                                            <LabeledData label={messages.users.role}>
                                                {user.role}
                                            </LabeledData>
                                        </Grid>
                                        <Grid item style={{ flexGrow: 1 }}>
                                            <LabeledData label={messages.users.id}>
                                                {user.username}
                                            </LabeledData>
                                        </Grid>
                                        {cube && (
                                            <Grid item>
                                                <LabeledData label="Standort">
                                                    {cube.description}
                                                </LabeledData>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        {/*{(user.role == UserRole.ApiUser && user.apiToken ) &&*/}
                        {/*<Grid item xs={12}>*/}
                        {/*    <Paper>*/}
                        {/*        <Box p={2}>*/}
                        {/*            <LabeledData label={messages.users.apiToken}>*/}
                        {/*                <ApiTokenDisplayComponent token={user.apiToken} />*/}
                        {/*            </LabeledData>*/}
                        {/*        </Box>*/}
                        {/*    </Paper>*/}
                        {/*</Grid>*/}
                        {/*}*/}
                    </Grid>
                    <Box my={2} />
                    {userStore.admin && (
                        <Fragment>
                            <Paper>
                                <Alert variant="outlined" severity="error">
                                    <Typography><strong>{messages.users.delete.title}</strong></Typography>
                                    <Typography>{messages.users.delete.hint}</Typography>
                                    <Box my={1} />
                                    <Button variant="contained" style={{ background: 'red', color: '#FFF' }} onClick={() => setDeleteModal(true)}>{messages.delete}</Button>
                                </Alert>
                            </Paper>
                            <Dialog open={deleteModal}>
                                <DialogTitle>{messages.users.delete.title}</DialogTitle>
                                <DialogContent>
                                    <Typography>{messages.users.delete.prompt}</Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setDeleteModal(false)}>{messages.cancel}</Button>
                                    <Button onClick={() => handleDeleteUser()}>{messages.delete}</Button>
                                </DialogActions>
                            </Dialog>
                        </Fragment>
                    )}

                </Fragment>
            )}
            { error && (
                <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </ContainerLayout>
    );
};
