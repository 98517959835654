import * as React from "react";
import {
    createStyles,
    Theme,
    WithStyles,
    withStyles,
    Grid, Link, Box
} from "@material-ui/core";
import {createElement} from "react";
import {messages} from "../i18n";
import customTheme from "../theme";
import {TenantsProvider} from "../domain/TenantsProvider";

interface FooterBarProps extends WithStyles<typeof useStyles>  { }

interface FooterBarState {
    allowSwitchTenant: boolean;
}

class FooterBar extends React.Component<FooterBarProps, FooterBarState> {

    constructor(props: FooterBarProps) {
        super(props);
        this.state = {
            allowSwitchTenant: false
        };
    }

    componentDidMount(): void {
        TenantsProvider.list()
            .then(tenants => {
                this.setState({allowSwitchTenant: tenants.length > 1});
            });
    }

    render() {
        const {classes} = this.props;
        const {allowSwitchTenant} = this.state;
        return (
            <Box display="block" displayPrint="none" className={classes.footerBar}>
                <Grid container spacing={1}>
                    <Grid item style={{flexGrow: 1}}>
                    </Grid>
                    {allowSwitchTenant &&
                    <Grid item>
                        <Link href="/tenants" underline="hover" className={classes.footerLink}>{messages.footerBar.switchTenant}</Link>
                    </Grid>
                    }
                    <Grid item>
                        <Link href="/api/docs" underline="hover" className={classes.footerLink}>{messages.footerBar.api}</Link>
                    </Grid>
                    <Grid item>
                        <Link href="https://www.variocube.com/kontakt/" underline="hover" className={classes.footerLink}>{messages.imprint}</Link>
                    </Grid>
                </Grid>
            </Box>
        );
    }

}

const useStyles = (theme: Theme) => createStyles ({
    footerBar: {
        marginTop: customTheme.spacing(6),
        color: customTheme.palette.primary.contrastText,
        backgroundColor: customTheme.palette.primary.main,
        padding: customTheme.spacing(2)
    },
    footerLink: {
        color: customTheme.palette.primary.contrastText
    }
});

export default withStyles(useStyles)(FooterBar);
