import * as React from "react";
import {createElement} from "react";
import {ImagePopover} from "./ImagePopover";

interface ImageWithPopoverProps {
    src: string;
    alt?: string;
}

interface ImageWithPopoverState {
    popoverOpen: boolean;
}

export class ImageWithPopover extends React.Component<ImageWithPopoverProps, ImageWithPopoverState> {

    static emptyState(): ImageWithPopoverState {
        return {
            popoverOpen: false
        };
    }

    constructor(props: ImageWithPopoverProps) {
        super(props);
        this.state = ImageWithPopover.emptyState();
    }

    private shopPopover() {
        this.setState({
            popoverOpen: true
        });
    }

    private hidePopover() {
        this.setState({
            popoverOpen: false
        });
    }

    render() {
        const {src, alt} = this.props;
        return (
            <React.Fragment>
                <img src={src} alt={alt} width="100%" style={{cursor: 'pointer'}}
                     onClick={(_) => this.shopPopover()} />
                <ImagePopover open={this.state.popoverOpen} onClose={() => this.hidePopover()} src={src} alt={alt} />
            </React.Fragment>
        );
    }

}
