import * as React from "react";
import {createElement, useEffect, useState} from "react";
import {RouteComponentProps, useHistory} from "react-router";
import {ContainerLayout} from "../ContainerLayout";
import {Box, Breadcrumbs, Button, Grid, Paper, Typography, ButtonGroup, TableContainer} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {gs} from "../../theme";
import {Crumb} from "../Crumb";
import {Invoice} from "../../domain/types";
import {InvoicesProvider} from "../../domain/InvoicesProvider";
import {userStore} from "../../domain/UserStore";
import {LabeledData} from "../LabeledData";
import {Loading} from "../Loading";
import {messages} from "../../i18n";
import {DateComponent, Display} from "../DateComponent";
import {ErrorPopup} from "../ErrorPopup";
import {FormattedAlert} from "../FormattedAlert";
import {ApiError} from '../../Api';
import {InvoiceInventoryTable} from "./InvoiceInventoryTable"
import {Monospace} from "../Monospace"
import { SendFab } from "../SendFab";

interface InvoiceViewProps extends RouteComponentProps<{id: string}>{
}

interface DlgSpec {
    title: string;
    message: string;
}

const useStyles = makeStyles((theme) => ({
  gridItemPadding: {
    padding: theme.spacing(3), //grid padding
  },
  rightAlignedGridItem: {
	paddingRight: theme.spacing(1),
  },
}));

export function InvoiceView(props: InvoiceViewProps) {
    const history = useHistory();
    const [invoice, setInvoice] = useState<Invoice>();
    const [error, setError] = useState<ApiError|undefined>(undefined);
    const [dlgSpec, setDlgSpec] = useState<DlgSpec|undefined>(undefined);
    const [confirmDlg, setConfirmDlg] = useState<boolean>(false);
    const classes = useStyles();

    useEffect(() => {
        const invoiceId = props.match.params.id;
        InvoicesProvider.get(userStore.getTenantId(), invoiceId).then(setInvoice);
    }, []);

    const resend = () => {
		const invoiceId = props.match.params.id;
        InvoicesProvider.resend(userStore.getTenantId(), invoiceId).then(toggleConfirm).catch(toggleError);
    }
    
    const toggleConfirm = () => {
		setConfirmDlg(true);
	}
    
    const toggleError = (error?: ApiError) => {
        if(error){
            setDlgSpec({
                title: messages.invoices.actions.failed.title,
                message: '<p>'+messages.categories.delete.productExistError.message+'</p>' + error.message
            });
        }else{
            setDlgSpec(undefined);
        }
        setError(error);
	};
    

    return (
        <ContainerLayout>
            <Grid container spacing={gs}>
                <Grid item xs={8}>
                    <Breadcrumbs>
                        <Crumb label={messages.invoices.plural} route="/invoices" />
                        <Crumb label={messages.invoices.singular+`: ${invoice ? invoice.customerEmail + ' '+ invoice.createdAt : '...'}`} />
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={4}>
                    <Typography align="right">
                    	<ButtonGroup>
	                        <Button onClick={history.goBack}>{messages.back}</Button>
	                        <Button onClick={resend} variant="contained" color="primary">{messages.invoices.actions.resend}</Button>
	                    </ButtonGroup>
	                </Typography>
				</Grid>
                {!invoice && <Loading />}
                {invoice &&
                <React.Fragment>
                    <Grid item xs={12} lg={8}>
                    	{ confirmDlg && (
							<FormattedAlert severity="success" title={messages.invoices.actions.successful.title} info={messages.invoices.actions.successful.message} />
						)}
                        <Grid container justify="flex-end">
                            <Grid item xs={12}>
                                <Paper>
					                <Grid xs={12} container>
				                    	<Grid item xs={12} sm={6}>
		                                    <Box p={2}>
				                            	<Typography variant="h4" gutterBottom>{messages.invoices.singular}</Typography>
		                                    </Box>
				                    	</Grid>
				                    	<Grid item xs={12} sm={6} container className={classes.gridItemPadding}>
					                        <Grid item xs={5} className={classes.rightAlignedGridItem}>
					                        	<Typography variant="body2" align="right">{messages.invoices.id}:</Typography>
					                        </Grid>
					                        <Grid item xs={7}>
					                        	{invoice.id}
					                        </Grid>
					                        <Grid item xs={5} className={classes.rightAlignedGridItem}>
					                        	<Typography variant="body2" align="right">{messages.invoices.createdAt}:</Typography>
					                        </Grid>
					                        <Grid item xs={7}>
					                    		<DateComponent date={invoice.createdAt} display={Display.Timestamp}/>
					                        </Grid>
					                        <Grid item xs={5} className={classes.rightAlignedGridItem}>
					                        	<Typography variant="body2" align="right">{messages.invoices.customerEmail}:</Typography>
					                        </Grid>
					                        <Grid item xs={7}>
					                    		{invoice.customerEmail}
					                        </Grid>
				                    	</Grid>
				                        
                                        <Grid item xs={12}>
                                        	<TableContainer>
                                        		<InvoiceInventoryTable inventories={invoice.lines}/>
                                        	</TableContainer>
                                        </Grid>
					                </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4} container spacing={gs}>
                    	<Grid item xs={12} sm={9} md={5} lg={12}>
                    		<Paper>
                    			<Box p={2}>
                    				<LabeledData label={messages.invoices.merchantReceipt}><Typography variant="body2"><Monospace>{invoice.merchantReceipt}</Monospace></Typography></LabeledData>
                    			</Box>
                    		</Paper>
                    	</Grid>
                    	<Grid item xs={12} sm={9} md={5} lg={12}>
                    		<Paper>
                    			<Box p={2}>
                    				<LabeledData label={messages.invoices.customerReceipt}><Typography variant="body2"><Monospace>{invoice.customerReceipt}</Monospace></Typography></LabeledData>
                    			</Box>
                    		</Paper>
                    	</Grid>
                    </Grid>
                </React.Fragment>
                }
            </Grid>
            { error && (
                <ErrorPopup url={error.url} statusCode={error.code} title={dlgSpec?.title} errorMessage={dlgSpec?.message} onCloseHandler={() => toggleError(undefined)}/>
            )}
            <SendFab onClick={resend}/>
        </ContainerLayout>
    );
}
